

/* eslint-disable */ 
import React, {useState,useEffect,useCallback} from 'react' ; 
import TextInput from '../../../components/TextInput' ; 
import Dropdown from '../../../components/Dropdown' ; 
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import Button from '../../../components/Button';
import { Colors } from '../../../Common/Colors';
import { useHistory } from "react-router-dom";
import Loader from '../../../components/Loader';

import './index.css'

export default function NewUser (props) {
    const history = useHistory();


    const {pending, addData} = props;

    
    const [userData, setUserData] = React.useState(props.userData||{})
    var edited = userData.id ; 

    const [userDataModified, setUserDataModified] = React.useState(null)

 
    const updateData = (id,data) => {
        setUserDataModified(userDataModified ? {
            ...userDataModified,
            [id] : data
        } : {[id] : data})
        setUserData({
            ...userData,
            [id] : data
        });
    }

    const TextField = ({id,placeholder,numeric,onChange}) => {
        if(numeric){
            return (
                <div className="p-field">
                    <label className="p-d-block" htmlFor={id}>{placeholder}</label>
                    <InputNumber 
                        id={id} 
                        min={0} 
                        showButtons
                        defaultValue={userData[id]} 
                        onChange={(e) => updateData(id, e.value)} 
                        style={{width:'100%'}}
                    />
                </div>
            )
        }
        return(
            <div className="p-field">
                <label className="p-d-block" htmlFor={id}>{placeholder}</label>
                <TextInput 
                    id={id} 
                    defaultValue={userData[id]} 
                    onChange={(e) => {
                        updateData(id,e.value ?  e.value : e.target.value)}
                    } 
                    style={{width:'100%'}}
                />
            </div>
        )
    }



    const TextFieldArea = ({id,placeholder}) => {
        return(
            <div className="p-field">
                <label className="p-d-block" htmlFor={id}>{placeholder}</label>
                <InputTextarea 
                    id={id} 
                    rows={10} 
                    defaultValue={userData[id]} 
                    onChange={(e) => updateData(id, e.target.value)} 
                    style={{width:'100%'}}
                />
            </div>
        )
    }
    return (
        <div>
    
            <div>
                <Button onClick={()=> history.push('/admin/liste-des-utilisateurs')} icon="pi pi-angle-left" label={'Liste des utilisateurs'}></Button>
            </div>
        
            
            <p style={{marginBottom:20, padding :30, backgroundColor:'#fff', fontSize:20, textAlign:'center', color: Colors.mainColor}}>
                {userData.id ? "Modification d'utilisateur" : "Création d'un nouvel utilisateur"}
            </p>

            <div className='p-d-flex'>
                <div className='marged p-md-6'>
                    {TextField({placeholder : 'Prénom', id : 'firstName'})}
                </div>
                <div className='marged  p-md-6'>
                    {TextField({placeholder : 'Nom', id : 'lastName'})}
                </div>
               
            </div>
            <div className='p-d-flex'>
                
                <div className='marged  p-md-6'>
                    {TextField({placeholder : 'Promotion', id : 'promotion'})}
                </div>

                <div className='marged  p-md-6'>
                    {TextField({placeholder : 'Username', id : 'username'})}
                </div>
            </div>
            <div className='p-d-flex'>
                
                <div className="p-field marged p-md-6">
                    <label className="p-d-block">{"Sexe"}</label>
                    <Dropdown placeholder='' onChange={ e => updateData("gender",e.value)} value={userData.gender}  optionLabel="" options={[{value: 'MALE', label: 'Homme'},{value: "FEMELLE", label: 'Femme'}]}  style={{width:'100%'}}/>
                </div>
            </div>
            {
                pending && (
                    <div style={{marginTop:20}}>
                        <Loader style={{minHeight:'auto'}}/>
                    </div>
                )
            }
            <div style={{display:'flex', justifyContent:'flex-end'}}>
                <Button disabled={pending} onClick={() => {console.log('userData',userData) ; addData(userData,userDataModified,edited)}} label={userData.id ? 'Enregistrer' : 'Ajouter'}></Button>
            </div>
        </div>
    )
}