/* eslint-disable */ 
import React, {useState, useEffect} from 'react' ;
import ElectionDetail from '../containers/ElectionDetail';
import {
    getElectionData,
    getUserVoteDataByElection,
    getConnectedUserCandidatureData,
    getElectionCandidates,
    handleError
} from '../Utils/API';
import Loader from '../components/Loader';
import suffle from '../Utils/Suffle';

export default function ElectionDetailsView (props) {

    const [election,setElection] = useState(null) ;
    const [userVotes, setUserVotes] = useState(false) ;
    const [candidatureData,setCandidatureData] = useState({}) ;
    const [voted,setVoted] = useState(false) ; 
    const fromVote = props.match.path.includes('votes') ; 
    const fromApplications = props.match.path.includes('candidatures') ; 

    const [loaded,setLoaded] = useState(false) ; 
    const getElectionDataFunc = () => {
        var id = props.match? props.match.params.id : props.electionData.id
        getElectionData(id ).then(results => {
            if(fromApplications){
                return ; 
            }
            getElectionCandidates(id).then(response => {
                console.log('response.data', response.data);
                var electionData = {
                    ...results.data,
                    candidates : suffle(response.data) || []
                } ; 
                setElection(electionData|| {})
                getUserVoteDataByElection(id).then(response => {
                    console.log('votes',response.data)
                    setUserVotes(response.data)
                    setVoted(response.data.length>0)
                    setLoaded(true)
                }).catch(handleError)
            }).catch(handleError)
        }).catch(handleError)

        if(fromVote){

        }

        if(fromApplications){
            getConnectedUserCandidatureData(id).then(response => {
                setCandidatureData(response.data)
            }).catch(handleError)
        }
    }

    useEffect(
        getElectionDataFunc,[]
    )

    if(!loaded || !election){
        return <Loader/>; 
    }


    return (
        <div>
            <ElectionDetail candidatureData={candidatureData} fromApplications={fromApplications} fromVote={fromVote} userVotes={userVotes} voted={voted} electionData={election} />
        </div>
    )
}