/* eslint-disable */ 
import React, {useState, useEffect} from 'react' ;
import NewElection from '../../containers/Admin/NewElection';
import { getElectionData,addElectionData  } from '../../Utils/API';
import Loader from '../../components/Loader';
import { useHistory } from "react-router-dom";

export default function NewElectionView (props) {
   
   
    const history = useHistory();
    const [electionData, setElectionData] = useState(null) ;
    const [pending, setPending] = useState(false) ;
    
    const loadElectionData = () => {
        var id =  props.match?.params.id  ;
        if(id){
            getElectionData(id).then(response => {
                setElectionData(response.data)
            })
        }
        else {
            setElectionData({})
        }
    }
    useEffect(
        loadElectionData,[]
    )

    if(!electionData){
        return <Loader/>; 
    }

    const addElection = (electionData,electionEdited,edited) => {
        if(!electionData.title || !electionData.description){
            return ; 
        }
        setElectionData(electionData)
        console.log('electionData',electionData)
        setPending(true) ; 
        if(electionEdited){
            addElectionData({...electionEdited, id : electionData.id },edited).then(response => {
                history.push('/admin/liste-des-elections')
            })
            return ;
        }
        addElectionData(electionData).then(response => {
            history.push('/admin/liste-des-elections')
        })
    }

    return (
        <div className='p-md-offset-3 p-col-12 p-md-6' style={{marginTop:40, minHeight : '100vh'}}>
            <NewElection addData={addElection} pending={pending} electionData={electionData} />
        </div>
    )
}