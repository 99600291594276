/* eslint-disable */ 
import React from 'react';
import { Menubar } from 'primereact/menubar';
import { Images } from '../../Common/Images';
import { labels } from '../../Common/AppTexts';
import { useHistory, Link } from "react-router-dom";
import './index.css';

/*
items: [
                {
                    label: 'En cours',
                    icon: 'pi pi-fw pi-play',
                    command: (event) => {
                        history.push('/')
                    }
                },
                {
                    separator: true
                },
                {
                    label: 'Historique',
                    icon: 'pi pi-fw pi-calendar-times',
                    command: (event) => {
                        history.push('/liste-des-elections')
                    }
                }
            ]
            */
export default function Header (props) {

    const history = useHistory();


    var items = [
        {
            label: 'Elections',
            icon: 'pi pi-fw pi-file',
            command: (event) => {
                history.push('/')
            },
        },
        {
            label: 'Mes votes',
            icon: 'pi pi-fw pi-check-square',
            command: (event) => {
                history.push('/mes-votes')
            }
        },
        {
            label: 'Mes candidatures',
            icon: 'pi pi-fw pi-star-o',
            command: (event) => {
                history.push('/mes-candidatures')
            }
        },
        {
            label: 'Resultats',
            icon: 'pi pi-fw pi-chart-bar',
            command: (event) => {
                history.push('/resultats')
            }
        },
        {
            label: 'Mon profil',
            icon: 'pi pi-fw pi-user',
            command: (event) => {
                history.push('/mon-profil')
            }
        }
    ];

    const disconnectItem = {
        label: labels.disconnect,
        icon: 'pi pi-fw pi-sign-out',
        command: (event) => {
            props.disconnect() ;
            history.push('login')
        }
    } ;
   
    const adminItems =  [
        {
            label: 'Administration',
            icon: 'pi pi-fw pi-cog',
            items: [
                {
                    label: 'Utilisateurs',
                    icon: 'pi pi-fw pi-user',
                    items: [
                        {
                            label: 'Liste des utilisateurs',
                            icon: 'pi pi-fw pi-user-edit',
                            command: (event) => {
                                history.push('/admin/liste-des-utilisateurs')
                            }
                        },
                        {
                            separator: true
                        },
                        {
                            icon: 'pi pi-fw pi-user-plus',
                            label: 'Ajouter un utilisateur',
                            command: (event) => {
                                history.push('/admin/nouvel-utilisateur')
                            }
                        }
                    ]
                },
                {
                    separator: true
                },
                {
                    label: 'Elections',
                    icon: 'pi pi-fw pi-file',
                    items: [
                        {
                            label: 'Liste des elections',
                            icon: 'pi pi-fw pi-list',
                            command: (event) => {
                                history.push('/admin/liste-des-elections')
                            }
                        },
                        {
                            separator: true
                        },
                        {
                            icon: 'pi pi-fw pi-plus-circle',
                            label: 'Créer une election',
                            command: (event) => {
                                history.push('/admin/nouvel-election')
                            }
                        }
                    ]

                }
            ]
        },
    ]

    items = props.isAdmin ?  [...adminItems,...items, disconnectItem] : [...items, disconnectItem] ;
 

    const start = <Link to='/'><img alt="logo" src={Images.logo} height="40" className="p-mr-2"/></Link>;

    return (
        <div>
            <div className="card">
                <Menubar 
                    id='menu'
                    model={items} 
                    start={start} 
                    //style={{position:'fixed', zIndex:1201, top:0, right:0, width:'100%'}}
                />
            </div>
            {props.children}
        </div>
    );
}
    