/* eslint-disable */
/* eslint-disable */
import axios from 'axios';
import React from 'react';
import './App.css';
import LoginView from './views/LoginView';
import MainView from './views/MainView';
import ProfilView from './views/ProfilView';
import ResultsView from './views/ResultsView';
import Header from './containers/Header';
import {HashRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import MyApplicationsView from './views/MyApplicationsView';
import NewElectionView from './views/Admin/NewElectionView';
import NewUserView from './views/Admin/NewUserView';
import UsersView from './views/Admin/UsersView';
import ElectionsView from './views/Admin/ElectionsView';
import MyVotesView from './views/MyVotesView';
import ElectionDetailsView from './views/ElectionDetailsView';
import PasswordRecoveryView from './views/PasswordRecoveryView';
import Loader from './components/Loader';
import MyApplicationDetailView from './views/MyApplicationDetailView';

const {WebSocket, location} = window;
const SOCKET_BASE_URL = process.env.REACT_APP_SOCKET_BASE_URL || 'wss://' + location.host + '/ws';
const RETRY_TIMEOUT = 5000;

export default class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            connected: null,
            isAdmin: false,
            loading: true,
            comments: []
        }
        this.socket = null;
        this.retry = 0;
        this.timer = null;
        this.disconnect = this.disconnect.bind(this);
        this.connectUser = this.connectUser.bind(this);
        this.onOpen = this.onOpen.bind(this);
        this.onMessage = this.onMessage.bind(this);
        this.onClose = this.onClose.bind(this);
        this.initSocketConnexion = this.initSocketConnexion.bind(this);
        this.autoConnection = this.autoConnection.bind(this);
        this.doLogin = this.doLogin.bind(this)
    }


    disconnect() {
        fetch('/api/auth', {
            method: 'DELETE'
        }).then(res => {
            localStorage.clear();
            this.setState({
                connected: null
            });
            window.location = '/'; // To reload
        }).catch(error => {
            console.log('error', error);
            this.setState({
                connected: null
            });
        });
    }

    connectUser(connected, callback) {
        callback()
        this.setState({
            connected: connected,
            isAdmin: connected.admin
        });
    }

    onOpen(event) {
        console.log('New connexion opened...', event);
        let socket = event.target
        if (socket) {
            socket.send(`{"type": "message", "message": "Initial connexion"}`);
        }
        if (this.timer) {
            window.clearInterval(this.timer);
            this.retry = 0;
        }
    }

    onMessage(event) {
        let eventData = event.data;
        console.log("onMessage", eventData);
        let data = JSON.parse(eventData) || {};
        if (data.type === 'comment') {
            this.setState({
                comments: data.data
            });
        }
        console.log('message_data', data);
        let socket = event.target
        if (socket) {
            socket.send(`{"type": "ack", "message": "Message received"}`);
        } else {
            console.log('socket is null :(');
        }
    }

    onClose(event) {
        console.log('connexion closed...', event);
        if (this.retry === 0) {
            this.retry++;
            this.timer = window.setInterval(() => {
                if (this.retry < 10) {
                    //this.initSocketConnexion();
                    this.retry++;
                    console.log('connexion lost, retry to  connect ', this.retry);
                } else {
                    console.log('retry max', this.retry);
                    window.clearInterval(this.timer);
                }
            }, RETRY_TIMEOUT);
        }
    }

    initSocketConnexion() {
        this.socket = new WebSocket(SOCKET_BASE_URL);
        this.socket.onopen = this.onOpen;
        this.socket.onmessage = this.onMessage;
        this.socket.onclose = this.onClose;
    }

    componentDidMount() {
        this.autoConnection();
    }

    autoConnection() {
        this.setState({
            loading: true
        });
        this.doLogin()
            .catch(error => {
                this.setState({
                    loading: false,
                    connected: null,
                    isAdmin: false
                }, () => {
                    console.log('error', error);
                    if (!location.hash.includes('initial')) {
                        location.href = '/#/login';
                    }
                });
            });
        //this.initSocketConnexion();
    }

    doLogin() {
        return axios.get('/api/auth')
            .then(res => {
                if (res.data.code !== 401) {
                    let connected = res.data;
                    this.setState({
                        connected: connected,
                        isAdmin: connected.admin
                    }, () => {
                        if (this.socket) {
                            this.socket.send(`{"type": "connexion", {"username":"${this.state.connected.username}"}}`);
                        } else {
                            console.log('sorry no socket defined.');
                        }
                    });
                }
                this.setState({
                    loading: false
                });
            })
    }

    render() {
        if (this.state.loading) {
            return <Loader/>
        }
        //console.log('---connected---', connected)
        return (
            //hashtag navigation
            <Router>
                <Switch>
                    <Route
                        path="/initial"
                        component={() => PasswordRecoveryView({connected: this.state.connected})}
                    />
                    {
                        this.state.connected ? (
                                <Header isAdmin={this.state.isAdmin} disconnect={this.disconnect}>
                                    <Route exact path="/">
                                        <MainView/>
                                    </Route>
                                    <Route exact path="/liste-des-elections">

                                        <MainView/>
                                    </Route>
                                    <Route exact path="/mes-candidatures">
                                        <MyApplicationsView/>
                                    </Route>

                                    <Route exact path="/mon-profil">
                                        <ProfilView/>
                                    </Route>
                                    <Route
                                        exact
                                        path="/mes-candidatures/:id"
                                        component={ElectionDetailsView}
                                    />
                                    <Route
                                        exact
                                        path="/candidature/:id"
                                        component={(props) => new MyApplicationDetailView({comments: this.state.comments, ...props})}
                                    />
                                    <Route exact path="/mes-votes">
                                        <MyVotesView/>
                                    </Route>
                                    <Route exact
                                           path="/resultats/:id"
                                           component={ResultsView}/>
                                    <Route exact path="/resultats">
                                        <ResultsView/>
                                    </Route>
                                    <Route
                                        exact
                                        path="/election/:id"
                                        component={ElectionDetailsView}
                                    />
                                    <Route
                                        exact
                                        path="/mes-votes/:id"
                                        component={ElectionDetailsView}
                                    />
                                    <Route exact path="/login">
                                        <LoginView initLogin={this.doLogin} connectUser={this.connectUser}
                                                   connected={this.state.connected}/>
                                    </Route>

                                    {
                                        this.state.isAdmin && (
                                            <>
                                                <Route exact path="/admin/liste-des-elections">
                                                    <ElectionsView/>
                                                </Route>
                                                <Route exact path="/admin/liste-des-utilisateurs">
                                                    <UsersView/>
                                                </Route>
                                                <Route exact path="/admin/nouvel-utilisateur">
                                                    <NewUserView/>
                                                </Route>
                                                <Route exact path="/admin/nouvel-election">
                                                    <NewElectionView/>
                                                </Route>
                                                <Route
                                                    exact
                                                    path="/admin/election/modification/:id"
                                                    component={NewElectionView}
                                                />

                                                <Route
                                                    exact
                                                    path="/admin/utilisateur/modification/:id"
                                                    component={NewUserView}
                                                />
                                                <Route
                                                    exact
                                                    path="/initial"
                                                    component={MainView}
                                                />
                                            </>
                                        )
                                    }

                                </Header>
                            )
                            :
                            (
                                <>
                                    <Route exact path="/login">
                                        <LoginView initLogin={this.doLogin} connectUser={this.connectUser} connected={this.state.connected}/>
                                    </Route>
                                    <Route
                                        exact
                                        path="/initial"
                                        component={PasswordRecoveryView}
                                    />
                                    <Redirect
                                        to={{
                                            pathname: "/login",
                                            from: window.location.hash
                                        }}
                                    />
                                </>
                            )
                    }
                </Switch>
            </Router>
        );
    }
}
