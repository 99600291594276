/* eslint-disable */ 
import React from 'react' ; 
import Button from '../Button';
import Dialog from '../Dialog';

export default function Confirm (props) {
    const {content,confirm, onClose, visible} = props ; 

    const footer = (
        <div>
            <Button label="Valider" icon="pi pi-check" onClick={confirm} />
            <Button label="Annuler" icon="pi pi-times" onClick={onClose} />
        </div>
    );

    const header = (
        <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
            <span>Confirmation des choix </span>

        </div>
    )
    return (
            <Dialog header={header} visible={visible} modal style={{ width: '350px' }} footer={footer} onHide={onClose}>
                <div className="confirmation-content">
                    
                    {content}
                </div>
            </Dialog>
    )
}