/* eslint-disable */ 
import React, {useState,useEffect} from 'react' ;
import Elections from '../../containers/Admin/Elections';
import Loader from '../../components/Loader';
import { getElections } from '../../Utils/API';
import { useHistory } from "react-router-dom";


export default function ElectionsView (props) {

    const history = useHistory();

    const [elections,setElections] = useState(null)
    const getElectionsData = () => {
        getElections().then(results => {
            console.log('results',results.data)
            setElections(results.data || [])
        })
    }
    useEffect(
        getElectionsData,[]
    )

    if(!elections){
        return <Loader/>; 
    }

    console.log('elections')

    return (
        <div className='p-md-offset-1 p-col-12 p-md-10' style={{marginTop:40, minHeight : '100vh'}}>
            <Elections history={history} elections={elections} />
        </div>
    )
}