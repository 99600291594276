
export const labels = {
    "username" : "Email",
    "password" : "Mot de passe",
    "code" : "Code",
    "disconnect" : "Deconnexion",
    "election" : "élection",
    "elections" : "élections",
    "any" : "aucune",
    "pending" : "en cours",
    "sortBy" : "Trier par",
    "endDateAsc" : "Date de fin croissant",
    "endDateDsc" : "Date de fin décroissant",
    "remaining" : "restant",
    "remainings" : "restants",
    "days" : 'jours',
    "day" : 'jour',
    "candidate" : "candidat",
    "candidater" : "Candidater",
    "candidates" : "candidats",
    "candidateDetail" : "En savoir plus",
    "electionsDetail" : "Détail",
    "electionsVoter" : "Commencer le vote",
    "electionsResults" : "Voir les résultats",
    "search" :"Recherche",
    "candidature"  :"candidature",
    "candidatures"  :"candidatures",
    "posts" : "postes",
    "post" : "poste",
    "terminated" : "terminée",
    "UseCode": "Utiliser un code",
    "UsePassword": "Utiliser un mot de passe"
}