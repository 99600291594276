/* eslint-disable */ 
import React, {useState} from 'react' ;
import SearchBar from '../../components/SearchBar';
import { labels } from '../../Common/AppTexts';
import Dropdown from '../../components/Dropdown';
import ResultCard from './ResultCard';
import { useHistory } from "react-router-dom";

export default function Results (props) {
    const history = useHistory();

    const [text, setText] = useState('')
    const [sortBy, setSortBy] = useState() ;

    const {elections, resultId} = props ;
    
    const [electionsSearched, setElectionsSearched] = useState(elections)
    const electionsCount = electionsSearched.length;
    const sortItems = [
        {
            name  : labels.endDateAsc,
            value : 'dateUp'
        },
        {
            name  : labels.endDateDsc,
            value : 'dateDown'
        }
    ] ;

    const makeSearch = (text) => {
        setText(text);
        const searchResults = elections.filter(election => {
            var {title} = election ;
            title = title?.toLowerCase() ;
            var textLowerCased = text.toLowerCase() ;
            return (title?.includes(textLowerCased))
        })
        setElectionsSearched(searchResults)
    }
    
    return (
        <div className='p-md-offset-2 p-col-12 p-md-8' style={{marginTop:40, minHeight : '100vh'}}>
           <SearchBar value={text} onChange={makeSearch} />
           <div style={{width:'100%'}}>
                <div>
                        <p style={{textAlign:'center'}}>{electionsCount ? electionsCount : labels.any} {electionsCount>1 ? labels.elections : labels.election}</p>
                </div>
                
           </div>

           <div style={{ marginTop:10}} >
                {
                    electionsSearched.map(election => {
                        return (
                            <ResultCard resultId={resultId} loadResultsData={props.loadResultsData} onClick={()=> history.push('/election/'+election.id)} data={election}/>
                        )
                    })
                }
           </div>
           
        </div>
    )
}