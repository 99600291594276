/* eslint-disable */
import React, {useEffect, useState} from 'react' ;
import Button from '../../components/Button';
import {Colors} from '../../Common/Colors';
import {labels} from '../../Common/AppTexts';
import {useHistory} from "react-router-dom";
import {createDateFormat, relativeTime, getFromNowDiff} from '../../Utils/Time';
import ReactHtmlParser, {processNodes, convertNodeToElement, htmlparser2} from 'react-html-parser';

import './index.css' ;

export default function ElectionCard(props) {

    const {
        id,
        candidatureEndDate,
        candidatureStartDate,
        voteStartDate,
        voteEndDate,
        createDate,
        postCount,
        active,
        title,
        description,
        candidateCount,
        candidature:
            {
                progress: candidaturePending,
                ended: candidatureEnded,
                started: candidatureStarted},
        vote: {
            progress: votePending,
            started: voteStarted,
            ended: voteEnded
        },
        ended,
        voterCount,
        userCount
    } = props.data || {}
    const history = useHistory();

    var publishDate = createDateFormat(createDate);

    const [leftingDay, setLeftingDay] = useState();
    const [timeText, setTimeText] = useState();
    const [pendingText, setPendingText] = useState();
    const [comingNext, setComingNext] = useState(false);

    const {onPress} = props;
    const endText = `${voterCount} votants | ${((voterCount/userCount)*100).toFixed(2)} % de participation`
    const getTimeInfos = () => {
        if(ended){
            setTimeText("Terminée " + relativeTime(voteEndDate))
            return
        }
        if (!active) {
            setPendingText('terminée');
            return
        }
        if (!candidatureStarted) {
            setPendingText("Lancement des candidatures");
            setTimeText(relativeTime(candidatureStartDate))
            setComingNext(true);
            return;
        }
        if (candidaturePending) {
            setPendingText("Candidature");
            if (candidatureEndDate) {
                setTimeText("termine " + relativeTime(candidatureEndDate))
                setLeftingDay(Math.abs(getFromNowDiff(candidatureEndDate)));
            }
            return;
        }
        if (!voteStarted) {
            setPendingText("Lancement des votes");
            setTimeText(relativeTime(voteStartDate))
            setComingNext(true);
            return;
        }

        if (votePending) {
            setPendingText("Vote");
            setTimeText("termine " + relativeTime(voteEndDate))
            setLeftingDay(Math.abs(getFromNowDiff(voteEndDate)));
            return;
        }
        
        setPendingText('terminée');
    }
    useEffect(
        getTimeInfos, []
    )

    return (
        <div style={{backgroundColor: Colors.white, padding: 20, height: '100%'}}>
            <div>
                <p style={{color: Colors.mainColor, marginBottom:0}}>{pendingText}</p>
                {ended && (<span style={{color :Colors.mainColor, fontSize:15}}>{endText}</span>)}
                <p style={{color: Colors.secondaryTextColor}}>
                    <i style={{color: ended || comingNext ? null : leftingDay >= 14 ? Colors.success : leftingDay >= 7 ? Colors.alert : Colors.danger}}
                       className="pi pi-circle-on p-mr-2"></i>
                    {timeText}
                </p>
                <p style={{
                    fontWeight: 'bold',
                    fontSize: 18,
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden'
                }}>{title}</p>
                <p style={{textAlign: 'justify'}} className='description'>{ReactHtmlParser(description)}</p>
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 10
            }}>
                <div>
                    <span style={{color: Colors.secondaryTextColor}}>
                        <i className="pi pi-calendar p-mr-2"></i>
                        {publishDate}
                    </span>
                </div>
                <div>
                    <span style={{color: Colors.secondaryTextColor}}>
                        <span style={{color: Colors.secondaryTextColor}}>
                        <span style={{marginRight: 5}}>
                        {postCount} {labels.post}{postCount > 1 ? 's' : ''}
                            <i className="pi pi-user p-ml-2"></i>
                        </span>
                        <span style={{marginRight: 5}}>
                            {candidateCount} cand.
                        </span>
                    </span>
                    </span>
                </div>

            </div>
            <div>
                <Button style={{width: '100%'}} label={votePending ? labels.electionsVoter : labels.electionsDetail}
                        onClick={() => onPress ? onPress() : history.push('/election/' + id)}></Button>
            </div>
        </div>
    )
}