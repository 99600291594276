/* eslint-disable */ 
import React from 'react' ;
import TextInput from '../TextInput';
import { labels } from '../../Common/AppTexts';

export default function SearchBar (props) {

    const {value, onChange} = props

    return (
        <div>
            <span style={{width:'100%'}} className="p-input-icon-left">
                <i className="pi pi-search" />
                <TextInput style={{width:'100%'}} className='p-inputtext-lg' value={value} onChange={(e) => onChange( e.target.value)} placeholder={labels.search} />
            </span>
        </div>
    )
}