/* eslint-disable */ 
import React, { useState, useEffect} from 'react' ;
import MyApplications from '../containers/MyApplications';
import {getConnectedUserCandidatureData, handleError} from '../Utils/API';
import Loader from '../components/Loader';

export default function MyApplicationsView () {

    //Candidature
    
    const [candidatures,setCandidatures] = useState(null)
    const getUserCandidatures = () => {
        getConnectedUserCandidatureData().then(results => {
            console.log('results',results.data)
            setCandidatures(results.data || [])
        }).catch(handleError)
    }
    useEffect(
        getUserCandidatures,[]
    )

    if(!candidatures){
        return <Loader/>; 
    }

    console.log('elections')
    return (
        <div>
            <MyApplications candidatures={candidatures}/>
        </div>
    )
}