/* eslint-disable */ 
import React from 'react' ;
import { labels } from '../../Common/AppTexts';
import ElectionDetailCard from '../ElectionDetailCard';
import Button from '../../components/Button';
import { Colors } from '../../Common/Colors';
import { useHistory } from "react-router-dom";
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-operators */

export default function ElectionDetail (props) {

    const history = useHistory();
    const {fromVote,fromApplications,userVotes, voted, candidatureData, onClose,electionData} = props ; 

    
    return (
        <div className={'p-md-offset-2 p-col-12 p-md-8'} style={{marginTop:40, minHeight : '100vh'}}>
           {(
                <div>
                    <Button onClick={()=> history.goBack()} label={fromVote ?"Mes votes" : fromApplications ? "Mes candidatures"  : "Retour"  } icon="pi pi-angle-left" style={{backgroundColor:Colors.white, borderColor : Colors.white, color : Colors.mainColor}}  />
                </div>
            )}
           <div style={{display:'flex',  flexDirection:'row', flexWrap:'wrap', marginTop: 10}} >
                <ElectionDetailCard candidatureData={candidatureData} fromApplications={fromApplications} onClose={onClose} fromVote={fromVote} data={electionData} userVotes={userVotes} voted={voted}/>
           </div>
        </div>
    )
}