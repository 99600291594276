/* eslint-disable */ 
import React, {useState} from 'react' ;
import SearchBar from '../../components/SearchBar';
import { labels } from '../../Common/AppTexts';
import Dropdown from '../../components/Dropdown';
import ElectionCard from '../ElectionCard';

export default function MyVotes (props) {

    const [text, setText] = useState('')
    const [sortBy, setSortBy] = useState() ;

    const {elections} = props ;
    
    /*const elections = [
        {
            leftingDay : 1,
            id : 1,
            publishDate : "23 Jun",
            title : 'Election1 présidentielle 2020-2021',
            description : 'sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ull',
            candidatesCount : 49
        },
        {
            leftingDay : 19,
            id : 2,
            publishDate : "23 Jun",
            title : 'Election2 présidentielle 2020-2021',
            description : 'sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ull',
            candidatesCount : 49
        },
        {
            leftingDay : 11,
            id : 3,
            publishDate : "23 Jun",
            title : 'Election3 présidentielle 2020-2021',
            description : 'sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ull',
            candidatesCount : 49
        },
        {
            leftingDay : 10,
            id : 4,
            publishDate : "23 Jun",
            title : 'Election4 présidentielle 2020-2021',
            description : 'sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ull',
            candidatesCount : 49
        }
    ] ;
    */
    const [electionsSearched, setElectionsSearched] = useState(elections)
    const electionCount = electionsSearched.length;
    const sortItems = [
        {
            name  : labels.endDateAsc,
            value : 'dateUp'
        },
        {
            name  : labels.endDateDsc,
            value : 'dateDown'
        }
    ] ;

    const makeSearch = (text) => {
        setText(text);
        const searchResults = elections.filter(election => {
            var {title, description} = election ;
            title = title?.toLowerCase() ;
            description = description?.toLowerCase() ;
            var textLowerCased = text.toLowerCase() ;
            return (title.includes(textLowerCased) || description.includes(textLowerCased))
        })
        setElectionsSearched(searchResults)
    }
    
    return (
        <div className='p-md-offset-2 p-col-12 p-md-8' style={{marginTop:40, minHeight : '100vh'}}>
           <SearchBar value={text} onChange={makeSearch} />
           {electionsSearched.length>0 ? (
            <div style={{display:'flex',  flexDirection:'row', flexWrap:'wrap', marginTop:10}} >
                    {
                    electionsSearched.map(election => {
                                return (
                                    <div className='p-col-12 p-md-4'>
                                        <ElectionCard data={election}/>
                                    </div>
                                )
                            })
                    }
            </div>
           )
           :
           (
                <div style={{display:'flex',  flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                    <p style={{textAlign:"center"}}> Vous n'avez pas encore voté </p>
                </div>
            )
            }
           
        </div>
    )
}