/* eslint-disable */
import React, {useState, useEffect, useCallback} from 'react' ;
import TextInput from '../../components/TextInput' ;
import Dropdown from '../../components/Dropdown' ;
import {InputTextarea} from 'primereact/inputtextarea';
import {InputNumber} from 'primereact/inputnumber';
import {Calendar} from 'primereact/calendar';
import Button from '../../components/Button';
import {Colors} from '../../Common/Colors';
import {useHistory} from "react-router-dom";
import Loader from '../../components/Loader';
import Avatar from '../../components/Avatar';

import './index.css'
import {getUserImage, updateUserProfile} from '../../Utils/API';
import {Images} from '../../Common/Images';
import { errorMessage } from '../../Utils/Message';

export default function Profil(props) {
    const history = useHistory();


    const [userData, setUserData] = React.useState(props.userData || {})

    const {pending, updateUserData} = props;

    if (!userData) {
        return <div/>
    }

    const updateData = (id, data) => {
        setUserData({
            ...userData,
            [id]: data
        });
    }

    const TextField = ({id, placeholder, numeric, onChange}) => {
        if (numeric) {
            return (
                <div className="p-field">
                    <label className="p-d-block" htmlFor={id}>{placeholder}</label>
                    <InputNumber
                        id={id}
                        min={0}
                        showButtons
                        defaultValue={userData[id]}
                        onChange={(e) => updateData(id, e.value)}
                        style={{width: '100%'}}
                    />
                </div>
            )
        }
        return (
            <div className="p-field">
                <label className="p-d-block" htmlFor={id}>{placeholder}</label>
                <TextInput
                    id={id}
                    defaultValue={userData[id]}
                    onChange={(e) => {
                        updateData(id, e.value ? e.value : e.target.value)
                    }
                    }
                    style={{width: '100%'}}
                />
            </div>
        )
    }


    const TextFieldArea = ({id, placeholder}) => {
        return (
            <div className="p-field">
                <label className="p-d-block" htmlFor={id}>{placeholder}</label>
                <InputTextarea
                    id={id}
                    rows={10}
                    defaultValue={userData[id]}
                    onChange={(e) => updateData(id, e.target.value)}
                    style={{width: '100%'}}
                />
            </div>
        )
    }
    console.log('userData', userData)

    const [profileUrl, setProfileUrl] = useState(userData.hasProfileImage ? getUserImage(userData.id) : userData.gender === "MALE" ? Images.male : Images.female);

    const updateProfile = (readerEvt) => {
        let imageData = readerEvt.target.result
        setProfileUrl(imageData)
        updateUserProfile(imageData)
    }
    const handleChange = e => {
        
        if (e.target.files.length) {
            let file = e.target.files[0];
            if(file.size>256000){
                errorMessage('La taille maximale autorisée est de 256 ko')
                return ; 
            }
            
            const reader = new FileReader();
            reader.onload = updateProfile
            reader.readAsDataURL(file)
        }
    };

    const hiddenFileInput = React.useRef(null);


    const selectImage = () => {
        hiddenFileInput.current.click();
    }

    //photo de profil
    let options = [
        {label: 'Homme', value: 'MALE'},
        {label: 'Femme', value: "FEMELLE"}
    ];
    return (
        <div style={{marginTop: 30}}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>

                <div style={{display: 'flex', marginBottom: 10, flexDirection: 'row', justifyContent: 'center'}}>
                    <Avatar src={profileUrl}/>
                </div>
                <Button onClick={selectImage} type='upload' label={"MODIFIER"}/>
                <input
                    type="file"
                    accept='image/*'
                    style={{display: "none"}}
                    onChange={handleChange}
                    ref={hiddenFileInput}
                />

            </div>

            <div className='p-d-flex p-offset-3 p-col-6' style={{flexDirection: 'column', paddingTop: 30}}>

                <div className='p-d-flex'>
                    <div className='marged p-md-6'>
                        {TextField({placeholder: 'Prénom', id: 'firstName'})}
                    </div>
                    <div className='marged  p-md-6'>
                        {TextField({placeholder: 'Nom', id: 'lastName'})}

                    </div>

                </div>
                <div className='p-d-flex'>

                    <div className='marged  p-md-6'>
                        {TextField({placeholder: 'Promotion', id: 'promotion'})}

                    </div>

                    <div className="p-field marged p-md-6">
                        <label className="p-d-block">{"Sexe"}</label>
                        <Dropdown placeholder='' onChange={e => updateData("gender", e.value)} value={userData.gender}
                                  optionLabel="" options={options} style={{width: '100%'}}/>
                    </div>
                </div>

                {
                    pending && (
                        <div style={{marginTop: 20}}>
                            <Loader style={{minHeight: 'auto'}}/>
                        </div>
                    )
                }
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button disabled={pending} onClick={() => updateUserData(userData)}
                            label={userData.id ? 'Enregistrer' : 'Ajouter'}></Button>
                </div>
            </div>
        </div>
    )
}