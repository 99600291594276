/* eslint-disable */ 
import React from 'react' ;
import Button from '../../components/Button';
import { Images } from '../../Common/Images';
import Dialog from '../../components/Dialog';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Colors } from "../../Common/Colors";
import { getUserImage } from '../../Utils/API';
import Avatar from '../../components/Avatar';

const backgroundColor = '#f8f9fa'
/* eslint-disable no-unused-vars */

export default function WinnerCard(props) {

    const [showCandidatureText, setShowCandidatureText] = React.useState() ;

    const [ref, setRef] = React.useState(null) ;

    const {
        firstName,
        lastName,
        gender,
        promotion,
        picture,
        voteCount,
        score,
        proportionVote,
        proportionScore,
        hasProfileImage,
        userId,
        status,
        candidateRank
    } = props.data;

    var name = firstName + ' '+lastName ; 
    //var genderText = gender == 'MALE' ? 'homme' : 'femme'

    const { showPosition,position, onlyWinners} = props ; 


    const getColor = () => {

        switch (status) {
            case 'elected':
                return '#2FA611'
              break;
            case 'waiting':
                return 'orange'
            default:
                'red'
        }
    }

    const profileUrl = (hasProfileImage) ? getUserImage(userId) : gender === "MALE" ? Images.male : Images.female

    if(onlyWinners && (status != 'elected')) {
        return <div/>
    }
    return (
        <div  style={{backgroundColor:backgroundColor, height:'100%', padding : 0,  borderRadius:20, width:'100%'}}>
            {
                showPosition && (
                    <span className='p-badge' style={{ marginLeft:10, marginTop:5, backgroundColor:getColor(), color:  '#fff'}}>#{candidateRank}</span>
                )
            }
            <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                <Avatar src={profileUrl} />
            </div>
            <div>
                <p style={{fontWeight:'bold', marginBottom:0,textAlign:'center'}}>{name}</p>
                {/*<p style={{textAlign:'center', marginTop:5, marginBottom:0}}>{genderText}</p>*/}
                {promotion > 0 && <p style={{textAlign:'center', marginTop:5}}>Promo {promotion}</p>}
            </div>
            <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                    <div style={{flex:1}}>
                        <p style={{textAlign:'center',fontSize:16, fontWeight:'bold', marginBottom:0, marginTop:0}}>{score}</p>
                        <p style={{textAlign:'center', color:'gray', marginTop:0,marginBottom:0}}>{"point" + (score > 1 ? 's' : '')}</p>
                    </div>
                    <div style={{flex:1}}>
                        <p style={{textAlign:'center', fontSize:16, fontWeight:'bold',marginBottom:0,marginTop:0}}>{proportionScore} %</p>
                        <p style={{textAlign:'center', color:'gray', marginTop:0,marginBottom:0}}>{voteCount} voix</p>
                    </div>
            </div>
        </div>
    )
}