/* eslint-disable */ 

import React, {useEffect, useState} from 'react' ;
import { Colors } from '../../Common/Colors';
import Button from '../../components/Button';
import Panel from '../../components/Panel';
import { fullDateTimeFormat } from '../../Utils/Time';
import Loader from '../../components/Loader';
import WinnerCard from './WinnerCard';



export default function ResultCard (props) {

    const {loadResultsData, resultId} = props;
    const { title,id} = props.data ;
    const [onlyWinners, setOnlyWinners]= React.useState(true) ;

    console.log('id', id);
    const [electionWinners, setElectionWinners] = React.useState(null)
    const [collapsed, setCollapsed] = useState(!resultId) ;

    const retrieveData = () => {
        loadResultsData(id).then(response => {
            setElectionWinners(response.data)
        }).catch(e => {
            //alert(e.message);
            setElectionWinners([])
        })
    }

    const loadData = async () => {
        if(collapsed) {
            retrieveData();
        }
    }

    const resultDisplay =  () => {
        if(!electionWinners) return <Loader style={{minHeight:'auto'}}/>; 
        if(electionWinners.length === 0){
            return (
                <p style={{textAlign:'center'}}>Les resultats de cette élection ne sont pas encore disponibles</p>
            )
        }
        //affichage des rangs
        return (
            <>
                <div style={{display:'flex',  flexDirection:'row', flexWrap:'wrap', marginTop:30}} >
                    {
                        electionWinners.map((winner,index) => {
                            return (
                                <div className='p-col-12 p-md-2'>
                                    <WinnerCard onlyWinners={onlyWinners} position={index+1} showPosition data={winner}/>
                                </div>
                            )
                        })
                    }
                </div>
                <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-end'}}>
                    <Button label={onlyWinners ? "Tous les candidats" : "Les élus"} onClick={()=> setOnlyWinners(!onlyWinners)}/> 
                </div>
            </>
        )
    }

    const showResults = () => {
        setCollapsed(!collapsed) ;
        loadData();
    }

    // header   
    const header = (
        <div>
            <p style={{ cursor:'pointer', color:Colors.mainColor}} onClick={/*props.onClick*/ showResults}>{title}</p>
        </div>            
    )

    useEffect(retrieveData, []);

    console.log('electionWinners',electionWinners)
    return (
        <div style={{marginBottom:20}}>
            <Panel header={header} collapsed={collapsed} toggleable onToggle={showResults}>
                <p>{resultDisplay()}</p>
            </Panel>
        </div>
    )
}