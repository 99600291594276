/* eslint-disable */ 
import React, {useState, useEffect} from 'react' ;
import Profil from '../containers/Profil';
import {connectedUserData, handleError, updateProfileInfo} from '../Utils/API';
import Loader from '../components/Loader';
import {errorMessage, successMessage} from "../Utils/Message";

export default function ProfilView () {


    const [userData,setUserData] = useState(null) ;
    const [pending,, setPending] = useState(false) ;

    const getUserData = () => {
        connectedUserData().then(results => {
            setUserData(results.data)
        }).catch(handleError)
    }
    useEffect(
        getUserData,[]
    )

    if(!userData){
        return <Loader/>; 
    }

    const updateData = (userData) => {
        console.log('userData',userData)
        let {id, firstName, lastName, promotion, gender} = userData;
        updateProfileInfo({id, firstName, lastName, promotion, gender}).then(res => {
            console.log(res);
            successMessage("Information mise à jour.");
        }).catch(err => {
            console.log('err', err);
            errorMessage(err.message || "Une erreur s'est produite.");
        });
    }

    return (
        <div>
            <Profil updateUserData={updateData} pending={pending} userData={userData}/>
        </div>
    )
}