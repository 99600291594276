/* eslint-disable */ 
import React, {useState} from 'react' ;
import SearchBar from '../../components/SearchBar';
import { labels } from '../../Common/AppTexts';
import Dropdown from '../../components/Dropdown';
import ApplicationCard from './ApplicationCard';
import { useHistory } from "react-router-dom";

export default function MyApplications (props) {
    const history = useHistory();

    const [text, setText] = useState('')
    const [sortBy, setSortBy] = useState() ;

    const {candidatures} = props ;
    
    /*const candidatures = [
        {
            leftingDay : 1,
            id : 1,
            publishDate : "23 Jun",
            title : 'Election1 présidentielle 2020-2021',
            description : 'sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ull',
            candidatesCount : 49
        },
        {
            leftingDay : 19,
            id : 2,
            publishDate : "23 Jun",
            title : 'Election2 présidentielle 2020-2021',
            description : 'sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ull',
            candidatesCount : 49
        },
        {
            leftingDay : 11,
            id : 3,
            publishDate : "23 Jun",
            title : 'Election3 présidentielle 2020-2021',
            description : 'sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ull',
            candidatesCount : 49
        },
        {
            leftingDay : 10,
            id : 4,
            publishDate : "23 Jun",
            title : 'Election4 présidentielle 2020-2021',
            description : 'sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ull',
            candidatesCount : 49
        }
    ] ;
    */
    const [electionsSearched, setElectionsSearched] = useState(candidatures)
    const candidaturesCount = electionsSearched.length;
    const sortItems = [
        {
            name  : labels.endDateAsc,
            value : 'dateUp'
        },
        {
            name  : labels.endDateDsc,
            value : 'dateDown'
        }
    ] ;

    const makeSearch = (text) => {
        setText(text);
        const searchResults = candidatures.filter(candidature => {
            var {description} = candidature ;
            description = description?.toLowerCase() ;
            var textLowerCased = text.toLowerCase() ;
            return (description?.includes(textLowerCased))
        })
        setElectionsSearched(searchResults)
    }
    
    return (
        <div className='p-md-offset-2 p-col-12 p-md-8' style={{marginTop:40, minHeight : '100vh'}}>
           <SearchBar value={text} onChange={makeSearch} />
           <div style={{width:'100%'}}>
                <div>
                        <p style={{textAlign:'center'}}>{candidaturesCount ? candidaturesCount : labels.any} {candidaturesCount>1 ? labels.candidatures : labels.candidature}</p>
                </div>
                
           </div>

           <div style={{ marginTop:10}} >
                {
                    electionsSearched.map(candidature => {
                        return (
                                 <ApplicationCard onClick={()=> history.push('/election/'+candidature.election.id)} data={candidature}/>
                        )
                    })
                }
           </div>
           
        </div>
    )
}