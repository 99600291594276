/* eslint-disable */ 
import React, {useState} from 'react' ;
import TextInput from '../../components/TextInput';
import { labels } from '../../Common/AppTexts';
import Button from '../../components/Button';
import { Images } from '../../Common/Images';

export default function PasswordRecovery (props) {

    const {pending} = props ;
    const [passwordData, setPasswordData] = useState({}) ;

    const reset = () => {
        props.resetPassword(passwordData)
    }
    return (
        <div className="p-col-12 p-md-6 p-lg-4 p-shadow-4">
            <div style={{padding:20, display:'flex', flexDirection:'row', justifyContent:'center'}}>
                <img style={{}} alt={"logo"} src={Images.logo}/>
            </div>
            <div className="p-md-12 p-mb-10">
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                        <i className="pi pi-user"></i>
                    </span>
                    <TextInput 
                        onKeyDown={(event) => (event.keyCode === 13) ? reset() : null}
                        value={passwordData.password} type='password' onChange={(e) => setPasswordData({...passwordData,password: e.target.value})} placeholder={"Mot de passe"} 
                    />
                </div>
            </div>

            <div className="p-md-12">
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                        <i className="pi pi-lock"></i>
                    </span>
                    <TextInput 
                        onKeyDown={(event) => (event.keyCode === 13) ? reset() : null}
                        type='password' value={passwordData.confirmPassword} onChange={(e) => setPasswordData({...passwordData,confirmPassword: e.target.value})} placeholder={"Confirmation du mot de passe"} />
                </div>
            </div>
            <div className='p-col-12 p-mt-10 p-d-flex d-flex-row p-ai-right p-jc-right'>
                <Button disabled={!passwordData.password || !passwordData.confirmPassword  } className='p-offset-8 p-col-4' label="Valider" onClick={reset} disabled={pending} icon={pending ? "pi pi-spin pi-spinner" : null} iconPos="right"  />
            </div>
        </div>
    )
}