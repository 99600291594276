/* eslint-disable */
import React, {useState, useEffect} from 'react' ;
import Button from '../../components/Button';
import {Colors} from '../../Common/Colors';
import {labels} from '../../Common/AppTexts';
import {useHistory} from "react-router-dom";
import Accordion from '../../components/AccordionTab';
import AccordionTab from '../../components/Accordion';
import "./index.css"
import UserCandidatureCard from '../UserCandidatureCard';
import Divider from '../../components/Divider';
import CustomDraggable from '../../components/DraggableCandidates';
import DraggableCandidates from '../../components/DraggableCandidates';
import {createDateFormat, getFromNowDiff, relativeTime} from '../../Utils/Time';
import Dialog from '../../components/Dialog';
import {InputTextarea} from 'primereact/inputtextarea';
import Loader from '../../components/Loader';
import {addCandidature, makeVote, connectedUserData, handleError} from '../../Utils/API';
import ReactHtmlParser, {processNodes, convertNodeToElement, htmlparser2} from 'react-html-parser';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Confirm from '../../components/Confirm';

export default function ElectionDetailCard(props) {
    const {fromVote, fromApplications, candidatureData, onClose} = props;
    const {
        id,
        candidatureEndDate,
        candidatureStartDate,
        candidates,
        voteStartDate,
        voteEndDate,
        createDate,
        postCount,
        active,
        title,
        description,
        maxVote,
        ended,
        candidature: {
            progress: candidaturePending,
            ended: candidatureEnded,
            started: candidatureStarted
        },
        vote: {
            progress: votePending,
            started: voteStarted,
            ended: voteEnded
        },
        voterCount,
        userCount
    } = props.data || {}
    const history = useHistory();

    const candidatesCount = candidates.length
    var publishDate = createDateFormat(createDate);

    const [leftingDay, setLeftingDay] = useState();
    const [timeText, setTimeText] = useState();
    const [pendingText, setPendingText] = useState();
    const [comingNext, setComingNext] = useState(false);

    const {onPress} = props;
    const getTimeInfos = () => {
        if(ended){
            setPendingText(`Terminée : ${voterCount} votants | ${((voterCount/userCount)*100).toFixed(2)} % de participation`) ;
            return
        }
        if (!active) {
            setPendingText('terminée');
            return
        }
        if (!candidatureStarted) {
            setPendingText("Lancement des candidatures");
            setTimeText(relativeTime(candidatureStartDate))
            setComingNext(true);
            return;
        }
        if (candidaturePending) {
            setPendingText("Candidature en cours.");
            if (candidatureEndDate) {
                setTimeText("fin " + relativeTime(candidatureEndDate))
                setLeftingDay(Math.abs(getFromNowDiff(candidatureEndDate)));
            }
            return;
        }
        if (!voteStarted) {
            setPendingText("Lancement des votes");
            setTimeText(relativeTime(voteStartDate))
            setComingNext(true);
            return;
        }

        if (votePending) {
            setPendingText("Vote en cours.");
            setTimeText("fin " + relativeTime(voteEndDate))
            setLeftingDay(Math.abs(getFromNowDiff(voteEndDate)));
            return;
        }
        setPendingText('terminée');
    }
    useEffect(
        getTimeInfos, []
    )

    const [showCandidatureDialog, setShowCandidatureDialog] = useState(false)
    const [candidatureText, setCandidatureText] = useState("")

    const [pending, setPending] = useState(false)
    const [candidatureSuccess, setCandidatureSuccess] = useState(false)

    const [alreadyCandidated, setCandidated] = useState(false);

    const updateAlreadyCandidated = () => {
        connectedUserData().then(response => {
            const isExisting = candidates.find(elt => elt.user.id === response.data.id);
            setCandidated(isExisting != null)
        }).catch(handleError)
    }
    useState(
        updateAlreadyCandidated, []
    )

    const addCandidatureFunc = () => {
        if (candidatureText) {
            setPending(true)
            addCandidature(id, {
                description: candidatureText
            }).then(response => {
                setPending(false);
                setCandidatureSuccess(true)
            })
                .catch(e => {
                    setPending(false);
                    setCandidated(true)
                })
        }
    }

    const footer = (
        <div>
            <Button label="Annuler" icon="pi pi-times" onClick={() => setShowCandidatureDialog(false)}/>
            <Button label="Valider" disabled={!candidatureText} icon="pi pi-check" onClick={addCandidatureFunc}/>
        </div>
    );


    const [voted, setVoted] = useState(props.voted);
    const [voting, setVoting] = useState(false);
    const [userVotes, setUserVotes] = useState(props.userVotes || []);

    console.log('ElectionDetailCard_VOTED',voted)
    const [choices, setChoices] = useState([]) ;


    const [showConfirm, setShowConfirm] = useState(false) ;


    const makeChoice = (voteList) => {
        setChoices(voteList) ;
        setShowConfirm(true)
    }

    const confirmChoice = () => {
        setShowConfirm(false)
        var voteList = choices ;
        if(!voteList || voteList.length === 0){
            return ;
        }
        setVoting(true)
        var voteData = [];
        voteList.map(elt => {
            voteData.push({candidateId: elt.id}) // Il faut ajouter l'id du candidat et non du user.
        })
        console.log('voteData', JSON.stringify(voteData))
        makeVote(id, voteData).then(response => {
            setVoted(true)
            setVoting(false)
        })
    }


    const content = (
        <>
            <p>Vous avez choisi les candidats suivants:</p>
            <ul>
                {
                    choices.map((choice,index)=> {
                        return (
                            <li style={{marginBottom:5}}>
                                Choix {index+1} : <span style={{fontWeight:'bold'}}>{choice.firstName} {choice.lastName}</span>
                            </li>
                        )
                    })
                }
            </ul>
        </>
    )

    return (
        <div style={{backgroundColor: Colors.white, padding: 20}} className='p-col-12'>
            <div>
                {!fromVote && !fromApplications && (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <p style={{color: Colors.secondaryTextColor}}>
                            <i style={{color: ended || comingNext ? null : leftingDay >= 14 ? Colors.success : leftingDay >= 7 ? Colors.alert : Colors.danger}}
                               className="pi pi-circle-on p-mr-2"></i>
                            {pendingText} {timeText}
                        </p>
                        {
                            ended ?
                                <Button style={{backgroundColor: Colors.mainColor}} label={labels.electionsResults}
                                        disabled={!ended} onClick={() => history.push('/resultats/' + id)}></Button> :
                                <Button style={{backgroundColor: Colors.mainColor}} label={labels.candidater}
                                        disabled={!candidaturePending} onClick={setShowCandidatureDialog}></Button>
                        }

                    </div>
                )

                }
                <p style={{fontWeight: 'bold', fontSize: 18, textAlign: 'center'}}>{title}</p>
                <p style={{textAlign: 'justify'}}>{ReactHtmlParser(description)}</p>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <div>
                    <span style={{color: Colors.secondaryTextColor}}>
                        <i className="pi pi-calendar p-mr-2"></i>
                        {publishDate}
                    </span>
                </div>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <span style={{color: Colors.secondaryTextColor}}>
                        <span style={{marginRight: 5}}>
                        {postCount} {labels.post}{postCount > 1 ? 's' : ''}
                            <i className="pi pi-user p-ml-2"></i>
                        </span>
                        <span style={{marginRight: 5}}>
                            {candidatesCount} {candidatesCount > 1 ? labels.candidates : labels.candidate}
                        </span>
                    </span>
                </div>
            </div>
            {
                fromApplications ? (
                    <p>
                        {candidatureData.candidature}
                    </p>
                )   
                :
                (
                <>
                        {(candidates && candidates.length > 0) && <DraggableCandidates  maxVote={maxVote} userVotes={userVotes} votePending={votePending} pending={voting} voted={voted} makeChoice={makeChoice} active={!ended} data={candidates} />}
                        <Dialog footer={(pending || candidatureSuccess || alreadyCandidated) ? null : footer} header={pending ? "Traitement en cours" : "Texte de candidature"} maximizable modal visible={showCandidatureDialog} style={{ width: '50vw' }}  onHide={() => setShowCandidatureDialog(false)}>
                            {alreadyCandidated ? (
                                <p style={{textAlign:'center', color:Colors.danger}}>Vous êtes déjà candidat à cette election</p>
                            )
                            :
                            candidatureSuccess ? 
                            (
                                <p style={{textAlign:'center', color:Colors.success}}>Votre candidature a été enregistrée avec succès</p>
                            )
                            :
                            (!pending ? (
                                <ReactQuill theme="snow" value={candidatureText} 
                                    onChange={(e) => setCandidatureText(e)}
                                    style={{backgroundColor:'#fff'}} 
                                />
                            ):
                            (
                                <Loader style={{minHeight : '100%'}}/>
                            ))}
                        </Dialog> 
                        <Confirm visible={showConfirm} content={content} confirm={confirmChoice} onClose={() => setShowConfirm(false)} />
                </>
                )
            }
        </div>
    )
}