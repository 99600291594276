/* eslint-disable */ 
import React, {useState, useEffect} from 'react' ;
import Main from '../containers/Main';
import { getElections, handleError } from '../Utils/API';
import Loader from '../components/Loader';

export default function MainView () {

    // liste des elections
    
    const [elections,setElections] = useState(null)
    const getElectionsData = () => {
        getElections().then(results => {
            console.log('results',results.data)
            setElections(results.data || [])
        }).catch(handleError)
    }
    useEffect(
        getElectionsData,[]
    )

    if(!elections){
        return <Loader/>; 
    }

    console.log('elections')
    return (
        <div>
            <Main elections={elections}/>
        </div>
    )
}