/* eslint-disable */
import React from 'react';
import {Button} from 'primereact/button';
import Divider from '../../components/Divider';
import Avatar from '../../components/Avatar';
import {addComment, getUserImage} from '../../Utils/API';
import {successMessage} from "../../Utils/Message";
import {relativeTime} from '../../Utils/Time';
import {Images} from '../../Common/Images';
import ReactQuill from 'react-quill';
import ReactHtmlParser from 'react-html-parser';
import './index.css'


export default function Comments(props) {

    const {comments} = props;
    const [value, setValue] = React.useState("");
    const [pending, setPending] = React.useState();


    const getCommentField = (comment) => {
        if (!(comment.candidate.id === props.candidatureId)) return <div/>
        const {createDate, author: {firstName, hasProfileImage, lastName, id, connectedUserId, gender}, content} = comment;
        const profileUrl = (hasProfileImage) ? getUserImage(id) : gender === "MALE" ? Images.male : Images.female
        //const fromMe = (id == props.connectedUserId);
        const fromCandidate = (id === props.candidateUserId);
        /*if(fromMe) {
            return (
                <div style={{display:'flex', justifyContent:'flex-end', flexDirection:'row'}}>
                    <div style={{display:'flex', maxWidth:'70%', marginBottom:5, alignItems:'top', flexDirection:'row', flex:1}}>
                        <div style={{flex:1, marginRight:10}}>
                            <p style={{marginTop:0, marginBottom:3, textAlign:'right'}}>
                                <p style={{fontWeight:'bold', marginTop:2, marginBottom:2, marginRight:5}}>{firstName} {lastName}</p> 
                                <p style={{marginTop:2, marginBottom:2}}>{relativeTime(createDate)}</p>
                            </p>
                            <p style={{color:'grey',  marginTop:3, textAlign:'justify'}}>{content}</p>
                        </div>
                        <div>
                            <Avatar
                                style={{borderRadius:50, backgroundColor:"#ddd", height:50, width:50}}
                                size='small'
                            />
                        </div>
                    </div>
                </div>
            )
        }*/
        return (
            <div style={{
                padding: 10,
                borderRadius: 5,
                marginBottom: 5,
                backgroundColor: fromCandidate ? '#DFF0D8' : '#F5F5F5'
            }}>
                <div style={{display: 'flex', marginBottom: 5, alignItems: 'top', flexDirection: 'row', flex: 1}}>
                    <div>
                        <Avatar
                            style={{borderRadius: 50, backgroundColor: "#ddd", height: 50, width: 50}}
                            size='small'
                            src={profileUrl}
                        />
                    </div>
                    <div style={{flex: 1, marginLeft: 10}}>
                        <p style={{marginTop: 0, marginBottom: 3}}>
                            <p style={{
                                fontWeight: 'bold',
                                marginTop: 2,
                                marginBottom: 2,
                                marginRight: 5
                            }}>{firstName} {lastName}</p>
                            <p style={{marginTop: 2, marginBottom: 2}}>{relativeTime(createDate)}</p>
                        </p>
                        <span style={{
                            color: 'grey',
                            marginTop: 3,
                            textAlign: 'justify'
                        }}>{ReactHtmlParser(content)}</span>
                    </div>
                </div>
            </div>
        )
    }

    const sendComment = () => {
        console.log('value', value)
        setPending(true)
        addComment({
            content: value,
            candidate: {id: props.candidatureId},
            author: {id: props.connectedUserId}
        }).then(response => {
            successMessage("Commentaire ajouté");
            setValue("");
            setPending(false)
        })
    }

    const getNewCommentField = () => {
        return (
            <div>
                <div style={{marginTop: 10}}>
                    <ReactQuill theme="snow"
                                value={value}
                                onChange={(e) => setValue(e)}
                                style={{backgroundColor: '#fff'}}
                    />
                </div>
                <div style={{display: 'flex', marginTop: 20, flexDirection: 'row', justifyContent: 'flex-end'}}>
                    <Button onClick={sendComment} icon="pi pi-send" label="Envoyer"/>
                </div>
            </div>
        )
    }

    const getEmptyComments = () => {
        return <p style={{textAlign: 'center'}}>Aucun commentaire en ce moment</p>
    }
    return (
        <>
            <Divider/>
            <p style={{textAlign: 'center'}}>Commentaires</p>
            <div>
                {(comments && comments.length > 0) ? comments.map(comment => {
                    return <div key={comment.id}>{getCommentField(comment)}</div>
                }) : getEmptyComments()}
            </div>
            <div>
                {getNewCommentField()}
            </div>

        </>
    )
}