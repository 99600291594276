/* eslint-disable */ 
import { message} from 'antd';

export const successMessage = (text,customTime) => {
  message.success(text, customTime ?  customTime : 5);
};

export const errorMessage = (text,customTime) => {
    message.error(text, customTime ?  customTime : 5);
};

