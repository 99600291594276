/* eslint-disable */
import React, {useState} from 'react' ;
import Login from '../containers/Login';
import {useHistory} from "react-router-dom";
import {connectedUserData} from '../Utils/API';
import axios from 'axios';
import {errorMessage} from '../Utils/Message';

export default function LoginView(props) {

    const history = useHistory();
    if (props.initLogin) {
        props.initLogin()
            .then(resp => {
                console.log('resp', resp);
                if (history.location.from && (history.location.from !== '/')) {
                    history.replace(`${history.location.from.split('#')[1]}`)
                } else {
                    history.push('/')
                }
            });
    } else if (props.connected) {
        console.log('history.location.from', history.location.from)
        if (history.location.from && (history.location.from !== '/')) {
            history.replace(`${history.location.from.split('#')[1]}`)
        } else {
            history.push('/')
        }
    }
    // Page de login
    // connexion au compte perso
    console.log('history.location.from', history.location.from)
    const [pending, setPending] = useState(false);

    const loginCallback = (response) => {
        if (response.data.id) {
            console.log('response', response)
            axios.defaults.headers.common['Authorization'] = response.headers.authorization;
            let isAdmin = response.data.admin
            localStorage.setItem('isAdmin', isAdmin)
            localStorage.setItem('Authorization', response.headers.authorization)
            let access_token = response.headers['x-auth-token']
            console.log('access_token', access_token)
            localStorage.setItem('access_token', access_token)
            const callBack = () => {
                if (history.location.from && (history.location.from !== '/')) {
                    history.replace(`${history.location.from.split('#')[1]}`)
                } else {
                    history.push('/')
                }
            }
            props.connectUser(response.data, callBack);

        }
    }

    const logUser = ({username, password}) => {
        setPending(true);
        connectedUserData({username, password})
            .then(loginCallback)
            .catch(e => {
                console.log('e', e);
                errorMessage('Identifiant ou mot de passe incorrect')
                setPending(false);
            })

    }
    //

    return (
        <div className="p-d-flex p-jc-center p-ai-center"
             style={{borderWidth: 1, borderStyle: 'solid', minHeight: '100vh'}}>
            <Login pending={pending} logUser={logUser} loginCallback={loginCallback}/>
        </div>
    )
}