import axios from 'axios';

import {errorMessage} from '../Utils/Message';

const BASE_URL = process.env.REACT_APP_BASE_URL || '';
const BASE_URL_VOTE = BASE_URL + '/api/vote'
const ADMIN_BASE_URL = BASE_URL + '/api'
const BASE_URL_AUTH = BASE_URL + '/api/auth'
const BASE_URL_USER = BASE_URL + '/api/user'

const {location} = window;

export const handleError = (error) => {
    console.log('error ---- ', error.code);
    console.log('error ---- ', error.toJSON());
    let err = error.toJSON();
    if (`${err.message}`.indexOf('code 401') > 0) {
        console.log('------------------ redirecting...... ------------------');
        errorMessage(err.message);
        location.href = '/#/login';
    }
};

const get = (endPoint) => {
    return axios.get(BASE_URL_VOTE + endPoint, {headers: {"Authorization": localStorage.getItem("Authorization")}})
}

const getCustomUrl = (url) => {
    return axios.get(url, {headers: {"Authorization": localStorage.getItem("Authorization")}});
}

const post = (endPoint, data) => {
    return axios.post(BASE_URL_VOTE + endPoint, data, {headers: {"Authorization": localStorage.getItem("Authorization")}});
}


const postCustomUrl = (url, data) => {
    return axios.post(url, data, {headers: {"Authorization": localStorage.getItem("Authorization")}});
}

const putCustomUrl = (url, data) => {
    console.log('data', data);
    return axios.put(url, data, {headers: {"Authorization": localStorage.getItem("Authorization")}});
}


const patchCustomUrl = (url, data) => {
    console.log('data', data);
    return axios.patch(url, data, {headers: {"Authorization": localStorage.getItem("Authorization")}});
}

export const getElections = () => {
    return get('/election')
}

export const getElectionData = (id) => {
    return get('/election/' + id)
}

export const getElectionCandidates = (id) => {
    return get('/election/' + id + '/candidates')
}

export const addCandidature = (id, candidatureData) => {
    console.log('candidatureData', candidatureData)
    return post('/election/' + id + '/candidates', candidatureData)
}

export const connectedUserData = (userData) => {
    console.log('userData', userData)
    if (userData) {
        return axios.post(BASE_URL_AUTH, userData);
    }
    return axios.get(BASE_URL_AUTH, {headers: {"Authorization": localStorage.getItem("Authorization")}});
}

export const makeVote = (id, voteData) => {
    return post('/election/' + id + '/votes', voteData)
}

export const getUserVotesByElection = () => {
    return get('/login')

}

export const getConnectedUserCandidatureData = () => {
    return get('/candidature/my')
}
export const getCandidatureData = (id) => {
    return axios.get(`${BASE_URL}/api/candidate/${id}`, {headers: {"Authorization": localStorage.getItem("Authorization")}});
}

export const addElectionData = (electionData, editing) => {
    console.log('electionData', electionData)
    if (editing) {
        return putCustomUrl(ADMIN_BASE_URL + '/election', electionData)
    }
    return postCustomUrl(ADMIN_BASE_URL + '/election', electionData)
}

export const getUsers = () => {
    return getCustomUrl(ADMIN_BASE_URL + '/user/list?page=-1&perPage=-1')
}

export const getUserData = (id) => {
    return getCustomUrl(ADMIN_BASE_URL + '/user/' + id)
}

export const addUserData = (userData, edited) => {
    if (userData.promotion) {
        userData.promotion = parseInt(userData.promotion)
    }
    if (userData.id) {
        return putCustomUrl(ADMIN_BASE_URL + '/user/' + userData.id, userData) // Fixed
    }
    return postCustomUrl(BASE_URL_USER + '/create', userData)
}

export const getUserVoteDataByElection = (electionId) => {
    return get('/election/' + electionId + '/my/candidates')
}

export const getUserVotedElections = () => {
    return get('/election/my')
}


export const loadElectionResults = (electionId) => {
    return get('/election/' + electionId + '/results')
}

export const getUserImage = (id) => {
    return `${BASE_URL_USER}/${id}/profile.png`
}

export const updateUserProfile = (data) => {
    postCustomUrl(`${BASE_URL_USER}/profile.png`, data.split('base64,')[1])
}

export const updateProfileInfo = (data) => {
    return patchCustomUrl(`${BASE_URL_USER}/update`, data)
}

export const updateUserPassword = (data) => {
    console.log('data', data)
    return postCustomUrl(`${BASE_URL_USER}/update`, data)
}

export const getAccessToken = (username) => {
    return axios.get(`${BASE_URL_AUTH}/accessToken/email`, {params: {username}});
}

export const loginWithToken = (accessToken) => {
    return axios.post(`${BASE_URL_AUTH}/accessToken/login`, accessToken);
}

export const updateCandidature = (id, data) => {
    console.log('data', data)
    return axios.put(`/api/vote/election/${id}/my-candidate`, data);
}

export const addComment = data => {
    return axios.post("/api/candidate-comment", data)
        .catch(handleError());
}
export const getCandidatureComments = (candidateId) => {
    return axios.get(`/api/candidate-comment/list?page=-1&perPage=-1&candidate.id=${candidateId}`)
        ;
}