/* eslint-disable */ 
import React, {useState,useEffect} from 'react' ;
import Users from '../../containers/Admin/Users';
import Loader from '../../components/Loader';
import { getUsers } from '../../Utils/API';
import { useHistory } from "react-router-dom";


export default function UsersView (props) {

    const history = useHistory();

    const [users,setUsers] = useState(null)
    const getUsersData = () => {
        getUsers().then(results => {
            console.log('results',results.data)
            setUsers(results.data || [])
        })
    }
    useEffect(
        getUsersData,[]
    )

    if(!users){
        return <Loader/>; 
    }

    console.log('users')

    return (
        <div className='p-md-offset-1 p-col-12 p-md-10' style={{marginTop:40, minHeight : '100vh'}}>
            <Users history={history} users={users} />
        </div>
    )
}