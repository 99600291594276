/* eslint-disable */
import React, {useState} from 'react';
import PasswordRecovery from '../containers/PasswordRecovery';
import {useHistory} from "react-router-dom";
import {updateUserPassword} from '../Utils/API';
import {errorMessage, successMessage} from '../Utils/Message';

export default function PasswordRecoveryView(props) {

    // Page de login
    // connexion au compte perso


    //var {token} =  props.match.params || {}

    const userToken = location.hash.split('token=')[1];

    const history = useHistory();

    if (props.connected) {
        history.push('/')
    }

    const [pending, setPending] = useState(false);
    const resetPassword = ({password, confirmPassword}) => {
        if (password && password.length < 8) {
            errorMessage('Le mot de passe doit contenir 8 caractères au minimum');
            return;
        }
        console.log('password, confirmPassword', password, confirmPassword)
        if (!(password === confirmPassword)) {
            errorMessage('Les 2 mots de passe ne sont pas identiques');
            return;
        }
        setPending(true);
        updateUserPassword({password, userToken, confirmPassword}).then(response => {
            successMessage('Votre mot de passe a été mis à jour avec succès.')
            history.push('/login')
        }).catch(e => {
            console.log('e', e);
            errorMessage('Une erreur est survenu, veuillez réessayer plus tard')
            setPending(false);
        })

    }

    return (
        <div className="p-d-flex p-jc-center p-ai-center"
             style={{borderWidth: 1, borderStyle: 'solid', minHeight: '100vh'}}>
            <PasswordRecovery pending={pending} resetPassword={resetPassword}/>
        </div>
    )
}