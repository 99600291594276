/* eslint-disable */ 
import React, {useState, useEffect} from 'react' ;
import NewUser from '../../containers/Admin/NewUser';
import { getUserData,addUserData  } from '../../Utils/API';
import Loader from '../../components/Loader';
import { useHistory } from "react-router-dom";

export default function NewUserView (props) {
   
   
    const history = useHistory();
    const [userData, setUserData] = useState(null) ;
    const [pending, setPending] = useState(false) ;
    
    const loadUserData = () => {
        var id =  props.match?.params.id  ;
        if(id){
            getUserData(id).then(response => {
                setUserData(response.data)
            })
        }
        else {
            setUserData({})
        }
    }
    useEffect(
        loadUserData,[]
    )

    if(!userData){
        return <Loader/>; 
    }

    const addUser = (userData,userDataModified,edited) => {
        
        if(!userData.gender || !!userData.email){
            return ; 
        }

        setUserData(userData)
        setPending(true) ; 

        if(userDataModified){
            addUserData({...userDataModified, id : userData.id },edited).then(response => {
                console.log('response', response);
                history.push('/admin/liste-des-utilisateurs')
            })
            return ; 
        }

        addUserData(userData).then(response => {
            console.log('response', response);
            history.push('/admin/liste-des-utilisateurs')
        })
    }

    return (
        <div className='p-md-offset-3 p-col-12 p-md-6' style={{marginTop:40, minHeight : '100vh'}}>
            <NewUser addData={addUser} pending={pending} userData={userData} />
        </div>
    )
}