/* eslint-disable */ 
import React, { useState, useEffect} from 'react' ;
import MyApplicationDetail from '../containers/MyApplicationDetail';
import {
    getCandidatureData,
    getCandidatureComments,
    getElectionData,
    connectedUserData,
    handleError
} from '../Utils/API';
import Loader from '../components/Loader';

export default function MyApplicationDetailView (props) {

    //Candidature
    
    var id = props.match? props.match.params.id : ""

    console.log('application_id',id)
    const [applicationData,setApplicationData] = useState()
    const [connectedUserId, setConnectedUserId] = React.useState() ;
    const[comments,setComments] = React.useState([]) ;
    const fecthCandidatureData = () => {
        getCandidatureData(id).then(results => {
            console.log('results_getCandidatureData',results.data)
            getElectionData(results.data.election.id).then(rep => {
                setApplicationData({...results.data, election : {...rep.data,...results.data}} || {})
            })
            getCandidatureComments(results.data.id).then(commentsFetched => {
                console.log('commentsFetched',commentsFetched.data)
                setComments(commentsFetched.data)
            })
        }).catch(handleError)
    }
    const getUserData = () => {
        connectedUserData().then(results => {
            setConnectedUserId(results.data.id)
        }).catch(handleError)
    }


    useEffect(
        ()=> {
            fecthCandidatureData()
            getUserData()
        },[]
    )

    useEffect (
        ()=> {
            setComments([...comments,...props.comments])
        },[props.comments]
    )

    if(!applicationData){
        return <Loader/>; 
    }

    console.log('elections')
    return (
        <div>
            <MyApplicationDetail comments={comments} electionId={id} connectedUserId={connectedUserId}  applicationData={applicationData} />
        </div>
    )
}