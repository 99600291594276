import moment from 'moment'
import 'moment/locale/fr'  // without this line it didn't work
moment.locale('fr')


export const createDateFormat = (date) => {
    return moment(date).format('ll')
    
}

export const getFromNowDiff = (date) => {
    return Math.abs(moment().diff(moment(date), 'days'))
}

export const formatDate = (date) => {
    return (moment(date).format('Do MMMM YYYY, hh:mm'))
}

export const fullDateTimeFormat = (date) => {
    return (moment(date).format('dddd Do MMMM YYYY à hh:mm'))
}
export const relativeTime = (datetime) => {
    return moment(datetime).endOf('minutes').fromNow();  
}