/* eslint-disable */
import React, {useState} from 'react' ;
import TextInput from '../../components/TextInput';
import {labels} from '../../Common/AppTexts';
import Button from '../../components/Button';
import {Images} from '../../Common/Images';
import {getAccessToken, loginWithToken} from "../../Utils/API";
import {errorMessage} from "../../Utils/Message";

export default function Login(props) {

    const {pending} = props;
    const [loginData, setLoginData] = useState({});
    const [useCode, setUseCode] = useState(false);
    const [accessToken, setAccessToken] = useState({});

    const logUser = () => {
        if (useCode) {
            loginWithToken(accessToken)
                .then(props.loginCallback)
                .catch(e => {
                    console.log('e', e);
                    errorMessage("Code invalid.");
                });
        } else {
            props.logUser(loginData)
        }
    }

    const getToken = () => {
        getAccessToken(loginData.username).then(res => {
            let data = res.data;
            console.log('data', data);
            if (data.code === 200) {
                setAccessToken({...accessToken, token: data.message})
            }
        }).catch(e => {
            console.log('e', e);
            errorMessage("Nom d'utilisateur introuvable.");
        });
    }

    return (
        <div className="p-col-12 p-md-6 p-lg-4 p-shadow-4">
            <div style={{padding: 20, display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                <img style={{}} alt={"logo"} src={Images.logo}/>
            </div>
            <div className="p-md-12 p-mb-10">
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                        <i className="pi pi-user"></i>
                    </span>
                    <TextInput
                        onKeyDown={(event) => (event.keyCode === 13) ? logUser() : null}
                        value={loginData.username}
                        onChange={(e) => setLoginData({...loginData, username: e.target.value})}
                        disabled={useCode && accessToken.token}
                        placeholder={labels.username}
                    />
                </div>
            </div>
            {(!useCode || accessToken.token) &&
            <div className="p-md-12">
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                        <i className="pi pi-lock"></i>
                    </span>
                    <TextInput
                        onKeyDown={(event) => (event.keyCode === 13) ? logUser() : null}
                        type='password' value={useCode ? accessToken.code : loginData.password }
                        onChange={(e) => useCode ? setAccessToken({
                            ...accessToken,
                            code: e.target.value
                        }) : setLoginData({...loginData, password: e.target.value})}
                        placeholder={useCode ? labels.code : labels.password}/>
                </div>
            </div>
            }

            <div className='p-col-12 p-mt-10 p-d-flex d-flex-row p-ai-right p-jc-right'>
                <label className='p-col-5'><input type='checkbox' checked={useCode}
                                                  onChange={(e) => setUseCode(e.target.checked)}/>{useCode === false ? labels.UseCode : labels.UsePassword}
                </label>
                <Button className='p-offset-3 p-col-4' label={useCode && !accessToken.token ? 'Continuer' : 'Connexion'}
                        onClick={(useCode && !accessToken.token) ? getToken : logUser}
                        disabled={pending}
                        icon={pending ? "pi pi-spin pi-spinner" : null} iconPos="right"/>
            </div>
        </div>
    )
}