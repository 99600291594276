/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-operators */
/* eslint-disable */ 
import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import UserCandidatureCard from "../../containers/UserCandidatureCard";
import { Colors } from "../../Common/Colors";
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import './index.css' ; 


const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};


const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;
  return result;
};

const grid = 3;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  background: 'transparent',

  ...draggableStyle
});

const getListStyle = (isDraggingOver,backgroundMarked) => ({
  background : backgroundMarked ? '#B6CFA5' : '#fff',
  display: "flex",
  //padding: grid,
  overflow: "auto",
  minHeight:  "NaN",
  alignItems : 'center'
});

export default class DraggableCandidates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items : props.data,
      selected: props.voted ? props.userVotes : [],
    };
    console.log('props.userVotes',props.userVotes)
    
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  
  id2List = {
    droppable: "items",
    droppable2: "selected",
    droppable3: "bottom"
  };

  getList = id => this.state[this.id2List[id]];

  onDragEnd = result => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        this.getList(source.droppableId),
        source.index,
        destination.index
      );

      let state = { items };

      if (source.droppableId === "droppable2") {
        state = { selected: items };
      }

      if (source.droppableId === "droppable3") {
        state = { bottom: items };
      }

      this.setState(state);
    } else {
      if(destination.droppableId =="droppable2" && (this.props.maxVote == this.getList("droppable2").length)){
        return ;
      }
      const result = move(
        this.getList(source.droppableId),
        this.getList(destination.droppableId),
        source,
        destination
      );
      if (result.droppable) {
        this.setState({ items: result.droppable });
      }
      if (result.droppable2) {
        this.setState({ selected: result.droppable2 });
      }
    }

  };

  translate = () => {
    const elt = document.getElementById('candidateRow') ; 
    var sLeft = elt.scrollLeft;
    var width = elt.scrollWidth
    var clientWidth = elt.clientWidth ; 
    elt.scrollLeft = sLeft+width-2*clientWidth;
  }

  translateToLeft = () => {
    const elt = document.getElementById('candidateRow') ; 
    var sLeft = elt.scrollLeft;
    var width = elt.scrollWidth
    var clientWidth = elt.clientWidth ; 
    elt.scrollLeft = sLeft-clientWidth/2;
  }

  render() {

    const {terminated,votePending,maxVote, userVotes, voted} = this.props ;
  
    const noInteraction = terminated || voted ; 
    const noDisplay = !votePending && !voted ; 
    return (
      <>
      <DragDropContext  onDragEnd={noInteraction ? null : this.onDragEnd}>
        <Droppable isDropDisabled={noInteraction} droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => {
            
            return (
            <div
              ref={provided.innerRef}
              id='candidateRow'
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {(this.state.items.length>0) && <p style={{cursor:'pointer'}} onClick={this.translateToLeft} className='floatingLeft'><i className='pi pi-angle-left'/></p>}
              {this.state.items.length ? this.state.items.map((item, index) => (
                <Draggable  key={""+item.id} draggableId={""+item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                      className='p-xs-6 p-md-2 p-col-6'
                    >
                      <UserCandidatureCard terminated={terminated} data={item} />
                    </div>
                  )}
                </Draggable>
              )):
              (
                <div style={{color:Colors.mainColor, borderColor:Colors.mainColor, textAlign:'center', width:'100%', borderStyle:'dashed', borderWidth:1, padding : 30, marginBottom:20}}>
                  <p>Glissez-deposez ici pour enlever un candidat</p>
              </div>  
              )}
              {provided.placeholder}
              {(this.state.items.length>0) && <p style={{cursor:'pointer'}} onClick={this.translate} className='floating'><i className='pi pi-angle-right'/></p>}
            </div>
            )}}
        </Droppable>
        {!noDisplay && <Droppable isDropDisabled={noInteraction} droppableId="droppable2" direction="horizontal">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver,this.state.selected.length)}
            >
              {this.state.selected.length ? this.state.selected.map((item, index) => (
                <Draggable key={""+item.id} draggableId={""+item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                      className='p-xs-6 p-md-2 p-col-6'
                    >
                      <UserCandidatureCard  showPosition position={(index+1)} terminated={terminated} data={item} />
                    </div>
                  )}
                </Draggable>
              )) : 
              (
                !voted && <div style={{color:Colors.mainColor, borderColor:Colors.mainColor, textAlign:'center', width:'100%', borderStyle:'dashed', borderWidth:1, padding : 30}}>
                  <p>Glissez-deposez ici les candidats par ordre de preference</p>
                  <p>Vous devez classer exactement {maxVote} {maxVote>1 ? "candidats" : "candidat"}</p>
                </div>       
              )}
              {provided.placeholder}
            </div>
          )}
      </Droppable>}
      </DragDropContext>
      {this.props.pending ? (
        <div style={{marginTop:20}}>
          <Loader style={{minHeight:'auto'}}/>
        </div>
      ) : this.props.voted &&  <p style={{textAlign:'center', color :Colors.success}}>Vous avez voté à cette election</p>}
      {votePending && !voted &&  <div style={{display:'flex', marginTop:20, flexDirection:'row', justifyContent:'flex-end'}}>
        <Button disabled={this.props.pending || this.props.voted || !this.props.votePending || this.state.selected.length != maxVote} label='Valider' onClick={()=> this.props.makeChoice(this.state.selected)}></Button>
      </div>}
      </>
    );
  }
}
