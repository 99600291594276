/* eslint-disable */ 
import React, {useState} from 'react' ;
import SearchBar from '../../components/SearchBar';
import { labels } from '../../Common/AppTexts';
import Dropdown from '../../components/Dropdown';
import ApplicationCard from './ApplicationCard';
import { useHistory } from "react-router-dom";

export default function MyApplicationDetail (props) {
    const history = useHistory();

    const [text, setText] = useState('')

    const {applicationData} = props ;
    console.log('applicationData',applicationData)
    return (
        <div className='p-md-offset-2 p-col-12 p-md-8' style={{marginTop:40, minHeight : '100vh'}}>
           <div style={{ marginTop:10}}>
                <ApplicationCard 
                    electionId={props.electionId}
                    connectedUserId={props.connectedUserId}
                    //onClick={()=> history.push('/election/'+candidature.election.id)} 
                    data={applicationData}
                    comments={props.comments}
                />
           </div>
        </div>
    )
}