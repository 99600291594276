/* eslint-disable */ 
import React from 'react' ;
import Button from '../../components/Button';
import { Images } from '../../Common/Images';
import Dialog from '../../components/Dialog';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Colors } from "../../Common/Colors";
import { getUserImage } from '../../Utils/API';
import Avatar from '../../components/Avatar';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import {labels} from '../../Common/AppTexts';
import {useHistory} from "react-router-dom";

/* eslint-disable no-unused-vars */

export default function UserCandidatureCard(props) {

    const [showCandidatureText, setShowCandidatureText] = React.useState() ;

    const [ref, setRef] = React.useState(null) ;
    const history = useHistory();
    const {data} = props ; 
    console.log('data',JSON.stringify(data))
    const {
        description,
        firstName,
        lastName,
        gender,
        user,
        id
    } = data;

    
    var name = firstName + ' '+lastName ; 
    var genderText = gender === 'MALE' ? 'Homme' : 'Femme'
    
    const {showPosition,position} = props ; 


    const profileUrl = (user && user.hasProfileImage) ? getUserImage(user.id) : (gender === "MALE" ? Images.male : Images.female)

    return (
        <div  onMouseLeave={(e) => ref.hide(e)} onMouseEnter={(e) => ref.show(e)} style={{backgroundColor:"#f8f9fa", height:'100%', padding : 0, paddingBottom:5,  borderRadius:20, width:'100%'}}>
            {
                showPosition && (
                <p style={{color:Colors.mainColor, marginLeft:10}}>#{position}</p>
                )
            }
            <div onClick={()=> history.push(`/candidature/${id}`)} style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                <Avatar src={profileUrl} />
            </div>
            <div>
                <p style={{fontWeight:'bold', marginBottom:5,textAlign:'center'}}>{name}</p>
                {/*<p style={{textAlign:'center', marginTop:5}}>{genderText}</p>*/}
            </div>
            <div style={{display:'flex', marginBottom:5, flexDirection:'row', justifyContent:'center'}}>
                <Button onClick={()=> setShowCandidatureText(true)} label={labels.candidateDetail} className="p-button-text" />
            </div>
           
            <Dialog header="Texte de candidature" maximizable modal visible={showCandidatureText} style={{ width: '50vw' }}  onHide={() => setShowCandidatureText(false)}>
                {ReactHtmlParser(description)}
            </Dialog> 

            <OverlayPanel ref={(el) => setRef(el)} style={{width: 400, height: 300, overflow: 'auto'}}>
                  {ReactHtmlParser(description)}
            </OverlayPanel>
        </div>
    )
}