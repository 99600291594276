

/* eslint-disable */ 
import React, {useState,useCallback} from 'react' ; 
import TextInput from '../../../components/TextInput' ; 
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import Button from '../../../components/Button';
import { Colors } from '../../../Common/Colors';
import { useHistory } from "react-router-dom";
import Loader from '../../../components/Loader';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './index.css'

export default function NewElection (props) {
    const history = useHistory();

    const {pending, addData} = props;
    const fr = {
        firstDayOfWeek: 1,
        dayNames: ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"],
        dayNamesShort: ["dim", "lun", "mar", "mer", "jeu", "ven", "sam"],
        dayNamesMin: ["D", "L", "M", "M", "J", "V", "S"],
        monthNames: ["janvier", "fevrier", "mars", "avril", "mai", "juin", "juillet", "aout", "septembre", "octobre", "novembre", "decembre"],
        monthNamesShort: ["jan", "fev", "mar", "avr", "mai", "jui", "jul", "aou", "sep", "oct", "nov", "dec"],
        today: "Aujourd'hui",
        clear: "effacer"
    };



    const [electionData,setElectionData] = React.useState(props.electionData||{})
    var edited = electionData.id ;

    const [electionEdited,setElectionEdited] = React.useState(null)


    const updateData = (id,data) => {
        console.log('data',data)
        setElectionData({
            ...electionData,
            [id] : data
        });
        
        setElectionEdited(electionEdited ? {
            ...electionEdited,
            [id] : data
        } : { [id] : data})
    }
    const TextField = ({id,placeholder,numeric,onChange}) => {
        if(numeric){
            return (
                <div className="p-field">
                    <label className="p-d-block" htmlFor={id}>{placeholder}</label>
                    <InputNumber 
                        id={id} 
                        min={0} 
                        showButtons
                        value={electionData[id]} 
                        onChange={(e) => e.value && updateData(id,e.value)} 
                        style={{width:'100%'}}
                    />
                </div>
            )
        }
        return(
            <div className="p-field">
                <label className="p-d-block" htmlFor={id}>{placeholder}</label>
                <TextInput 
                    id={id} 
                    defaultValue={electionData[id]} 
                    onChange={(e) => updateData(id,e.target.value)}
                    style={{width:'100%'}}
                />
            </div>
        )
    }

    const TextFieldArea = ({id,placeholder}) => {
        return(
            <div className="p-field">
                <label className="p-d-block" htmlFor={id}>{placeholder}</label>
                <InputTextarea 
                    id={id} 
                    rows={10} 
                    defaultValue={electionData[id]} 
                    onChange={(e) => updateData(id,e.target.value)} 
                    style={{width:'100%'}}
                />
            </div>
        )
    }



    const DateField = ({id,placeholder}) => {
        return(
            <div className="p-field">
                <label className="p-d-block" htmlFor={id}>{placeholder}</label>
                <Calendar 
                    id={id} 
                    showIcon
                    showTime
                    value={electionData[id] ? new Date(electionData[id]) : null} 
                    onChange={(e) => updateData(id, new Date(e.value))} 
                    style={{width:'100%'}}
                    locale={fr} dateFormat="dd/mm/yy"
                />
            </div>
        )
    }



    const getWYGIWYS = ({id,placeholder}) => {
        return(
            <div className="p-field">
                <label className="p-d-block" htmlFor={id}>{placeholder}</label>
                <ReactQuill theme="snow" value={electionData[id]} 
                    onChange={(e) => updateData(id,e)}
                    style={{backgroundColor:'#fff'}} 
                />
            </div>
        )
    }


    console.log('ready')
  
    return (
        <div>
    
            <div>
                <Button onClick={()=> history.push('/admin/liste-des-elections')} icon="pi pi-angle-left" label={'Liste des élections'}></Button>
            </div>
        
            <p style={{marginBottom:20, padding :30, backgroundColor:'#fff', fontSize:20, textAlign:'center', color: Colors.mainColor}}>
                {electionData.id ? "Modification d'élection" : "Création d'une nouvelle élection"}
            </p>
            <div className='marged'>
                {TextField({placeholder : 'Titre' , id : 'title'})}
            </div>
            <div className='marged'>
                {getWYGIWYS({placeholder : 'Description' , id : 'description'})}
            </div>
            <div className='p-d-flex'>
                <div className='p-md-4'>
                    {TextField({numeric : true , placeholder : 'Places' , id : 'postCount'})}
                </div>
                <div className='p-md-4'>
                    {TextField({numeric : true, placeholder : 'Vote max' , id : 'maxVote'})}
                </div>
                <div className='p-md-4'>
                    {TextField({numeric : true, placeholder : 'Par genre' , id : 'minByGender'})}
                </div>
            </div>
            <div className='p-d-flex'>
                <div className='p-md-6'>
                    {DateField({placeholder : 'Deb. can' , id : 'candidatureStartDate'})}
                </div>
                <div className='p-md-6'>
                    {DateField({placeholder : 'Fin can' , id : 'candidatureEndDate'})}
                </div>
                
            </div>
            <div className='p-d-flex'>
                
                <div className='p-md-6'>
                    {DateField({placeholder : 'Deb. vote' , id : 'voteStartDate'})}
                </div>
                <div className='p-md-6'>
                    {DateField({placeholder : 'Fin vote' , id : 'voteEndDate'})}
                </div>
                
            </div>
            {
                pending && (
                    <div style={{marginTop:20}}>
                        <Loader style={{minHeight:'auto'}}/>
                    </div>
                )
            }
            <div style={{display:'flex', justifyContent:'flex-end'}}>
                <Button disabled={pending} onClick={() => addData(electionData,electionEdited,edited)} label={electionData.id ? 'Enregistrer' : 'Ajouter'}></Button>
            </div>
        </div>
    )
}