/* eslint-disable */ 
import React from 'react' ;
import { Colors } from '../../Common/Colors';
import Button from '../../components/Button';
import Panel from '../../components/Panel';
import { fullDateTimeFormat } from '../../Utils/Time';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Avatar from '../../components/Avatar';
import { Images } from '../../Common/Images';
import Comments from './Comments' ; 
import { Card } from 'primereact/card';
import { useHistory } from "react-router-dom";
import ReactQuill from 'react-quill';
import {successMessage} from "../../Utils/Message";
import { updateCandidature } from '../../Utils/API';
import {getUserImage} from '../../Utils/API';
import 'react-quill/dist/quill.snow.css';

export default function ApplicationCard (props) {
    const history = useHistory();
    const {data,connectedUserId,electionId} = props ; 
    const {election,user,firstName,lastName, id,description,gender } = data ;
    const [editing, setEditing] = React.useState() ; 
    const [pending, setPending] = React.useState() ; 

    var name = firstName + ' '+lastName ; 

    const [candidatureText,setCandidatureText] = React.useState(description) ; 

    const profileUrl = (user && user.hasProfileImage) ? getUserImage(user.id) : gender === "MALE" ? Images.male : Images.female

    const toggle = () => {
        if(editing){
            setPending(true)
            sendCandidature().then(response => {     
                setPending(false)
                successMessage("Modification effectuée") 
                setEditing(!editing)
            })
        }
        else {
            setEditing(!editing)
        }
    }

    const sendCandidature = () => {
        return updateCandidature(electionId,{id : id, user: {id : connectedUserId}, description :  candidatureText})
    }
    
    const getHeader = () => {
        console.log('connectedUserId',connectedUserId,'id',id)
        const fromMe = (user.id === connectedUserId) ;

        return (
            fromMe ? (
                <>
                    <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                        <Avatar src={profileUrl} />
                        <p style={{fontWeight:'bold'}}>{name}</p>
                    </div>
                    {((!election.candidature.ended)) && <div style={{display:'flex', marginBottom:20, marginTop:20, flexDirection:'row', justifyContent:'flex-end'}}>
                        <Button onClick={toggle} disabled={pending} label={pending ? 'Modification en cours' : !editing ? "Modifier ma candidature" : "Enregistrer"}  style={{backgroundColor:  Colors.mainColor, borderColor : Colors.white, color : Colors.white,}}  />
                    </div>}
                </>
            )
            :
            (
                <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                    <Avatar src={profileUrl} />
                    {name}
                </div>
            )
        )
    }
    return (
            <>
                <div style={{marginBottom:10}}>
                    <Button  onClick={()=> history.goBack()} label={"Retour"  } icon="pi pi-angle-left" style={{backgroundColor:Colors.white, borderColor : Colors.white, color : Colors.mainColor}}  />
                </div>
                <Card>
                    <div style={{marginBottom:20}}>
                        {getHeader()}
                        {editing ? (
                            <ReactQuill theme="snow" value={candidatureText} 
                                onChange={(e) => setCandidatureText(e)}
                                style={{backgroundColor:'#fff'}} 
                            />
                        )
                        :
                        (
                            <>
                                <p>{ReactHtmlParser(candidatureText || description)}</p>
                                <Comments comments={props.comments} connectedUserId={connectedUserId} candidatureId={id} candidateUserId={user.id} />
                            </>
                        )}
                    </div>
                </Card>
            </>
    )
}