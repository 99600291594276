/* eslint-disable */ 
import React, { useState, useEffect} from 'react' ;
import Results from '../containers/Results';
import {getElections, loadElectionResults, getElectionData, handleError} from '../Utils/API';
import Loader from '../components/Loader';

export default function ResultsView (props) {


    const [elections,setElections] = useState(null)
    const [resultId, setResultId] = useState(null)
    const getElectionsData = () => {
        var id = props.match ? props.match.params.id : null
        if (id) {
            setResultId(id);
            getElectionData(id).then(results => {
                console.log('results', results.data)
                setElections(results.data ? [results.data] : [])
            }).catch(handleError)
        } else {
            getElections().then(results => {
                console.log('results', results.data)
                setElections(results.data || [])
            }).catch(handleError)
        }
    }
    useEffect(
        getElectionsData,[]
    )

    if(!elections){
        return <Loader/>; 
    }

    console.log('elections')
    return (
        <div>
            <Results resultId={resultId} loadResultsData={loadElectionResults} elections={elections}/>
        </div>
    )
}