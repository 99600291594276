/* eslint-disable */ 
import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import { formatDate } from '../../../Utils/Time';
import Button from '../../../components/Button';
import { Link } from "react-router-dom";
import './index.css' ;
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-operators */
export default class Users extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {field: 'createDate', header: 'Ajout'},
            {field: 'firstName', header: 'Prénom'},
            {field: 'lastName', header: 'Nom'}  ,          
            {field: 'promotion', header: 'Promo'},
            {field: 'gender', header: 'Sexe'}                  
        ];

        this.state = {
            selectedColumns: this.columns,
            users: this.format(props.users)
        }

        this.onColumnToggle = this.onColumnToggle.bind(this);
    }

    format = (data) => {
        var dataFormatted = [] ;
        data.map(elt => {
            dataFormatted.push({
                ...elt,
                createDate : formatDate(elt['createDate']),
            })
        })
        return dataFormatted ;
    }
   
    onColumnToggle(event) {
        let selectedColumns = event.value;
        let orderedSelectedColumns = this.columns.filter(col => selectedColumns.some(sCol => sCol.field === col.field));
        this.setState({ selectedColumns: orderedSelectedColumns });
    }

    render() {
        const header = (
            <div style={{ textAlign:'left' }}>
                <MultiSelect selectedItemsLabel={'{0} sélections'} value={this.state.selectedColumns} options={this.columns} optionLabel="header" onChange={this.onColumnToggle} style={{width:'20em'}}/>
            </div>
        );

        const columnComponents = this.state.selectedColumns.map(col=> {
            return <Column  sortable key={col.field} field={col.field} header={col.header} />;
        });

        return (
            <div>
                <div style={{marginBottom:20, display:'flex', justifyContent:'flex-end'}}>
                    <Link style={{textDecoration:'none'}} to='/admin/nouvel-utilisateur'><Button label='AJOUTER'></Button></Link>
                </div>
                <div className="datatable-responsive-demo">
                    <DataTable 
                        value={this.state.users} 
                        //header={header}
                        resizableColumns 
                        columnResizeMode="expand"
                        className="p-datatable-gridlines p-datatable-striped p-datatable-responsive-demo"
                        onSelectionChange={e => {
                             this.props.history.push('/admin/utilisateur/modification/'+e.value.id)
                        }} 
                        selectionMode="single" 
                    >
                        {columnComponents}
                        {//<Column field="action" header="Actions" />
                        }
                    </DataTable>
                </div>
            </div>
        );
    }
}