/* eslint-disable */ 
import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import { formatDate } from '../../../Utils/Time';
import Button from '../../../components/Button';
import { Link } from "react-router-dom";
import './index.css' ;

export default class Elections extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {field: 'createDate', header: 'Ajout'},
            {field: 'title', header: 'Titre'},
            {field: 'description', header: 'Description'},
            {field: 'postCount', header: 'Places'},
            {field: 'maxVote', header: 'Vote Max'},
            {field: 'minByGender', header: 'Min par genre'},
            {field: 'candidatureStartDate', header: 'Debut cand'},
            {field: 'candidatureEndDate', header: 'Fin cand'},
            {field: 'voteStartDate', header: 'Debut vote'},
            {field: 'voteEndDate', header: 'Fin vote'},
            
        ];

        this.state = {
            selectedColumns: this.columns,
            elections: this.format(props.elections)
        }

        this.onColumnToggle = this.onColumnToggle.bind(this);
    }

    format = (data) => {
        var dataFormatted = [] ;
        data.map(elt => {
            dataFormatted.push({
                ...elt,
                candidatureStartDate : formatDate(elt['candidatureStartDate']),
                candidatureEndDate : formatDate(elt['candidatureEndDate']),
                candidatureStartDate : formatDate(elt['candidatureStartDate']),
                voteStartDate : formatDate(elt['voteStartDate']),
                voteEndDate : formatDate(elt['voteEndDate']),
                createDate : formatDate(elt['createDate']),
            })
        })
        return dataFormatted ;
    }
   
    onColumnToggle(event) {
        let selectedColumns = event.value;
        let orderedSelectedColumns = this.columns.filter(col => selectedColumns.some(sCol => sCol.field === col.field));
        this.setState({ selectedColumns: orderedSelectedColumns });
    }

    template = (data,id) => {
        console.log('data',data) ; 
        return (
            <p className='template'> {data[[id]]}</p>
        )
    }
    render() {
        const header = (
            <div style={{ textAlign:'left' }}>
                <MultiSelect selectedItemsLabel={'{0} sélections'} value={this.state.selectedColumns} options={this.columns} optionLabel="header" onChange={this.onColumnToggle} style={{width:'20em'}}/>
            </div>
        );

        const columnComponents = this.state.selectedColumns.map(col=> {
            return <Column body={col.field == "description" || col.field == "title" ? (data)=>this.template(data,col.field) : null}  sortable key={col.field} field={col.field} header={col.header} />;
        });

        return (
            <div>
                <div style={{marginBottom:20, display:'flex', justifyContent:'flex-end'}}>
                    <Link style={{textDecoration:'none'}} to='/admin/nouvel-election'><Button label='AJOUTER'></Button></Link>
                </div>
                <div className="datatable-responsive-demo">
                    <DataTable 
                        value={this.state.elections} 
                        header={header}
                        resizableColumns columnResizeMode="expand"
                        className="p-datatable-gridlines p-datatable-striped p-datatable-responsive-demo"
                        onSelectionChange={e => {
                             this.props.history.push('/admin/election/modification/'+e.value.id)
                        }} 
                        selectionMode="single" 
                    >
                        {columnComponents}
                        {//<Column field="action" header="Actions" />
                        }
                    </DataTable>
                </div>
            </div>
        );
    }
}