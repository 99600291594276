export const Colors = {
    mainColor: '#3498db',
    headerColor: "#333",
    textColor:  '#495057',
    mainBackgroundColor : "#f3f3f4",
    black : '#000',
    secondaryTextColor :"#A4A3A3",
    white : "white",
    alert :"orange",
    danger :"red",
    success : "green"
}