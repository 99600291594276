/* eslint-disable */ 
import React from 'react' ;
import { Colors } from '../../Common/Colors';
import Button from '../../components/Button';
import Panel from '../../components/Panel';
import { fullDateTimeFormat } from '../../Utils/Time';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

export default function ApplicationCard (props) {


    const {description,election,createDate} = props.data ;

    const header = <div>
        <p>{election?.title} | <span style={{fontSize:13}}>{fullDateTimeFormat(createDate)}</span></p>
    </div>
    return (
        <div style={{marginBottom:20}}>
            <Panel header={header} toggleable>
                <p>{ReactHtmlParser(description)}</p>
                <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-end'}}>
                    <Button onClick={props.onClick} label="VOIR ELECTION"/>
                </div>
            </Panel>
        </div>
    )
}